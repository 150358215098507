app-provably-fair {
  .mat-mdc-tab-group {
    gap: 2rem;
    height: 100%;

    .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab {
      color: var(--color-typography-muted-50);

      &--active {
        // color: var(--color-typography-light);

        .mdc-tab-indicator .mdc-tab-indicator__content {
          color: var(--color-primary);
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;
      border-radius: 0.5rem;
      overflow: hidden;
      background: linear-gradient(180deg, var(--color-theme-400) -100%, var(--color-theme-500) 100%);
    }
  }
}

.tabs-default {
  --background-color: var(--color-gray-700);
  --border-color: var(--color-gray-600);
  --active-color: var(--color-blue-500);
  --active-text-color: var(--color-gray-150);
  --active-hover-color: var(--color-blue-400);

  &.mat-mdc-tab-group {
    // ripple colors
    --mat-tab-header-active-ripple-color: none;
    --mat-tab-header-inactive-ripple-color: none;

    .mat-mdc-tab-header {
      --mat-tab-header-label-text-size: 0.875rem;
      --mat-tab-header-label-text-tracking: normal;
      --mat-tab-header-label-text-weight: 400;

      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            padding: 4px;
            border-radius: 40px;
            background-color: var(--background-color);

            .mat-mdc-tab {
              height: 100%;
              padding: 0.7rem 1rem;
              border-radius: inherit;

              .mdc-tab__content {
                .mdc-tab__text-label {
                  // active label colors
                  --mat-tab-header-active-label-text-color: var(--active-text-color);
                  --mat-tab-header-active-hover-label-text-color: var(--active-text-color);
                  --mat-tab-header-active-focus-label-text-color: var(--active-text-color);

                  // inactive label colors
                  --mat-tab-header-inactive-label-text-color: var(--color-gray-400);
                  --mat-tab-header-inactive-hover-label-text-color: var(--active-text-color);
                  --mat-tab-header-inactive-focus-label-text-color: var(--active-text-color);
                }
              }

              .mdc-tab-indicator {
                border-radius: inherit;

                .mdc-tab-indicator__content {
                  height: 100%;
                  border-radius: inherit;
                  background-color: var(--active-color);

                  &--underline {
                    border: none;
                  }
                }
              }
            }

            @media screen and (width < 480px) {
              height: 36px;
            }
          }
        }
      }
    }
  }
}

app-lobby {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-labels {
        width: fit-content;
        height: 48px;

        @media screen and (width < 768px) {
          width: 100%;
        }

        .mat-mdc-tab {
          padding: 0.75rem 2rem;

          .mdc-tab:hover {
            .mdc-tab-indicator__content {
              background-color: var(--active-hover-color);
            }
          }
        }
      }
    }
  }
}

// Стили для табов в правом меню
.p2p-panel {
  --active-color: var(--color-gray-550);

  .mat-mdc-tab-header {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--color-gray-400);

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-labels {
        height: min-content;
        width: 100%;

        .mat-mdc-tab {
          padding: inherit;
          align-items: center;
          height: 2.25rem;
        }
      }
    }
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

// Табы маркета
.market-filter-tabs {
  &.tabs-default {
    &.mat-mdc-tab-group {
      .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
          .mat-mdc-tab-labels {
            .mat-mdc-tab {
              padding: 0.65rem 1rem 0.75rem;
            }
          }
        }
      }
    }
  }
}

// Табы лобби
.top-winnings-header .tabs-default .mat-mdc-tab-header {
  display: none;
}

// Зеленый тогл
.toggle-green.tabs-default {
  --background-color: var(--color-green-500);
  --active-color: var(--color-gray-800);

  .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab {
    padding: calc(var(--height) / 3);
    min-width: auto;
  }
}

// Табы в модалке завершения трейда
.semi-round-tabs.tabs-default.mat-mdc-tab-group {
  --active-color: var(--color-gray-600);

  &.hide-tabs {
    mat-tab-header {
      display: none;
    }
  }

  mat-tab-header {
    width: max-content;

    .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
      border-radius: 16px 16px 0 0;
      padding: 0;
    }
  }
}

app-p2p {
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

.trade-tabs {
  & > div.mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
  }

  .mat-mdc-tab-group {
    flex: 1 1 auto;
  }
}

// .winning-tabs mat-tab-header {
//   width: 70%;
//   position: absolute;
//   right: 0;
//   z-index: 2;
//   bottom: 20.5rem;
// }
