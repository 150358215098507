html, body {
  height: 100%;
  width: 100%;
}

/* NOTE: Needed to hide chatra widget and open by our own buttons */
#chatra:not(.chatra--expanded) {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
  transition: none;
}
