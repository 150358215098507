@mixin style-class($prop, $map, $responsive: false) {
  @each $key, $val in $map {
    .#{$key} {
      #{$prop}: #{$val} !important;
    }
  }

  @if  $responsive {
    @each $key, $val in $breakpoints {
      @media screen and (min-width: #{$val}) {
        .#{$key + $separator} {
          @each $key, $val in $map {
            &#{$key} {
              #{$prop}: #{$val} !important;
            }
          }
        }
      }
    }
  }
}
