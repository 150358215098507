a {
  color: var(--color-secondary);
  text-decoration: none;
  cursor: pointer;

  &:hover:not(.decoration-none),
  &:active:not(.decoration-none) {
    text-decoration: underline;
  }

  &:disabled,
  &[href=''],
  &[href='#'] {
    color: var(--color-typography-muted-30);
    text-decoration: none;
    pointer-events: none;
  }

  &.cdk-keyboard-focused {
    outline: 2px solid var(--color-outline);
  }
}

.mat-tab-link {
  font-size: 1rem !important;
  font-weight: normal !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  opacity: 1 !important;
  letter-spacing: 1.03px;
  color: var(--color-typography-muted-30) !important;
  padding: 0 0.25rem !important;
  margin: 0 1rem;
  min-width: auto !important;

  &:first-child {
    margin-left: 0 !important;
  }

  &:hover {
    color: var(--color-typography-light) !important;
    text-decoration: none;
  }

  &.mat-tab-label-active {
    color: var(--color-typography-light) !important;
  }
}

.mat-ink-bar {
  height: 4px !important;
  bottom: -2px !important;
}

.mat-tab-header,
.mat-tab-link-container {
  overflow: visible !important;
}

.mat-tab-nav-bar {
  margin: 24px 0 2rem;
}
