/* Firefox */
html,
.custom-scroll {
  //scrollbar-width: thin;
  //scrollbar-color: var(--color-gray-450) var(--color-theme-600);
}

/* Chrome, Edge and Safari */
html::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

html::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--color-theme-600);
}

html::-webkit-scrollbar-track:hover,
.custom-scroll::-webkit-scrollbar-track:hover {
  background-color: var(--color-theme-600);
}

html::-webkit-scrollbar-track:active,
.custom-scroll::-webkit-scrollbar-track:active {
  background-color: var(--color-theme-600);
}

html::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-gray-450);
}


html::-webkit-scrollbar-thumb:hover,
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray-450);
}

html::-webkit-scrollbar-thumb:active,
.custom-scroll::-webkit-scrollbar-thumb:active {
  background-color: var(--color-gray-450);
}

.custom-scroll--hover {
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray-450);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-theme-600);
    }
  }
}

.custom-scroll--hide {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

ng-scrollbar {
  &.hide-scroll {
    --scrollbar-thumb-color: none;
    --scrollbar-track-color: none;
  }
}

app-participation-panel {
  ng-scrollbar {
    &.scroll-y-offset-right {
      div {
        scrollbar-y {
          right: calc((1rem + var(--scrollbar-padding)) * -1) !important;
        }
      }
    }
  }
}

.global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
  left: 0;
  top: 0;
}
