.mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-label-text-color: white;
  --mat-tab-header-inactive-hover-label-text-color: white;
  --mat-tab-header-inactive-focus-label-text-color: white;

  a {
    text-decoration: none !important; // TODO: убрать, когда удалим _link.scss
  }

  &.cases-navigation {
    height: 3.5rem;
    border: none;

    .mat-mdc-tab-link-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-links {
          place-content: flex-end center;

          .mat-mdc-tab-link {
            min-width: fit-content;
            font-size: 1rem;
            opacity: 1 !important;
            color: var(--color-gray-300);
            border-bottom: 1px solid var(--color-gray-400);
            transition: color 150ms ease-in-out;
            height: 3rem;
            overflow: visible;
            text-decoration: none;

            .mdc-tab__content {
              .mdc-tab__text-label {
                gap: 0.5rem;
                color: #fff;

                .link-title {
                  position: relative;
                  color: var(--color-gray-300);
                  transition: color 150ms ease-in-out;
                }

                .free-cases-amount {
                  background: var(--color-yellow-500);
                  color: var(--color-gray-800);
                  border-radius: 0.25rem;
                  padding: 0.25rem 0.5rem;
                }

                app-ui-tag {
                  position: absolute;
                  left: 100%;
                  bottom: 100%;

                  --font-weight: 500;
                  --padding: 0;
                  --size: 0.75rem;
                  --background-color: none;
                  --text-color: var(--color-green-600);
                }

                .new-cases-point {
                  position: absolute;
                  left: 100%;
                  bottom: 100%;
                  width: 0.575rem;
                  height: 0.575rem;
                  background-color: var(--color-green-600);
                  clip-path: circle(45% at 50% 50%);
                }
              }
            }

            .mdc-tab-indicator {
              height: calc(100% + 0.5rem);

              .mdc-tab-indicator__content {
                bottom: 0.075rem;
                height: 0.5rem;
                background-color: var(--color-yellow-500);
                border: none;
                clip-path: polygon(
                  0% 0%,
                  calc(50% - 0.2rem) 0%,
                  50% 50%,
                  calc(50% + 0.2rem) 0%,
                  100% 0%,
                  calc(100% - 0.25rem) 40%,
                  calc(50% + 0.25rem) 40%,
                  50% 100%,
                  calc(50% - 0.25rem) 40%,
                  calc(0% + 0.25rem) 40%
                );
                color: var(--color-yellow-500);
              }
            }

            &.mdc-tab--active {
              .link-title {
                color: var(--color-yellow-500);
                opacity: 1 !important;
              }
            }

            &.mat-mdc-tab-disabled {
              .link-title {
                color: var(--color-gray-400);
              }
            }

            &:hover {
              .mdc-tab__content .mdc-tab__text-label .link-title {
                color: var(--color-gray-400);
              }
            }

            &.free {
              .mdc-tab__content .mdc-tab__text-label .link-title {
                color: var(--color-green-600);
              }

              .mdc-tab__content .mdc-tab__text-label .free-cases-amount {
                background: var(--color-green-600);
              }

              &:hover {
                .mdc-tab__content .mdc-tab__text-label .link-title {
                  color: var(--color-gray-400);
                }
              }
            }
          }
        }
      }
    }

    .mat-ripple {
      // visibility: visible !important;
      color: var(--color-gray-200);
      height: 3rem;
      box-shadow: none !important;
    }
  }

  //TODO вынести общие стили в отдельный класс
  &.market-cases-navigation {
    height: 3.5rem;
    border: none;

    .mat-mdc-tab-link-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-links {
          place-content: flex-end center;

          .mat-mdc-tab-link {
            min-width: fit-content;
            font-size: 1rem;
            opacity: 1 !important;
            color: var(--color-gray-400);
            transition: color 150ms ease-in-out;
            height: 3rem;
            overflow: visible;
            text-decoration: none;

            .mdc-tab__content {
              .mdc-tab__text-label {
                gap: 0.5rem;
                color: #fff;

                .link-title {
                  position: relative;
                  color: var(--color-gray-400);
                  transition: color 150ms ease-in-out;
                }

                .free-cases-amount {
                  background: var(--color-yellow-500);
                  color: var(--color-gray-800);
                  border-radius: 0.25rem;
                  padding: 0.25rem 0.5rem;
                }

                app-ui-tag {
                  position: absolute;
                  left: 100%;
                  bottom: 100%;

                  --font-weight: 500;
                  --padding: 0;
                  --size: 0.75rem;
                  --background-color: none;
                  --text-color: var(--color-green-600);
                }

                .new-cases-point {
                  position: absolute;
                  left: 100%;
                  bottom: 100%;
                  width: 0.575rem;
                  height: 0.575rem;
                  background-color: var(--color-green-600);
                  clip-path: circle(45% at 50% 50%);
                }
              }
            }

            .mdc-tab-indicator {
              height: calc(100% + 0.5rem);

              .mdc-tab-indicator__content {
                height: 0.85rem;
                background: radial-gradient(circle at 50% 200%, rgb(152 124 13) -50%, rgb(18 23 25 / 0%) 45%);
                border-bottom: 1px solid;
                border-color: var(--color-yellow-500);
                border-top-width: 0;
              }
            }

            &.mdc-tab--active,
            &:hover {
              .link-title {
                color: var(--color-yellow-500) !important;
                opacity: 1 !important;
              }
            }

            &.mat-mdc-tab-disabled .link-title {
              color: var(--color-gray-400);
            }

            &.free {
              .mdc-tab__content .mdc-tab__text-label .link-title {
                color: var(--color-green-600);
              }

              .mdc-tab__content .mdc-tab__text-label .free-cases-amount {
                background: var(--color-green-600);
              }

              &:hover {
                .mdc-tab__content .mdc-tab__text-label .link-title {
                  color: var(--color-gray-400);
                }
              }
            }
          }
        }
      }
    }

    .mat-ripple {
      // visibility: visible !important;
      color: var(--color-gray-200);
      height: 3rem;
      box-shadow: none !important;
    }
  }
}

// &.category_free {
//   .mat-tab-nav-bar {
//     .mat-tab-link-container {
//       .mat-tab-list {
//         .mat-ink-bar {
//           background: var(--color-green-600);
//         }
//       }
//     }
//   }
// }
