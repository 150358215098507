.seo {
  h1 {
    margin: 40px 0;
    font-size: 36px;
    line-height: 120%;
    font-weight: 600;
  }

  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    margin: 40px 0;
  }

  h3 {
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    margin: 1rem 0;
  }

  p {
    letter-spacing: 0.2px;
    color: #fff;
    opacity: 0.7;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 0.5rem;
  }

  ul,
  ol {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #fff;
    opacity: 0.7;

    li {
      margin: 0.75rem 0 0.75rem 2rem;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 0.5rem;
    border: 1px solid #277486;
  }
}

@media screen and (width <= 768px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }
}
