.widget-icon {
  flex: 0 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icomoon {
    font-size: 42px;
  }
}

.widget-content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.widget-content-header {
  user-select: none;
  font-size: 0.75rem;
  padding: 0;
  margin: 0 0 0.625rem;
  font-weight: bold;
  letter-spacing: 0.9px;
  line-height: normal;

  &-name {
    text-transform: uppercase;
  }

  &-status {
    color: var(--color-attention-error);
  }
}

.widget-content-link,
.widget-content-description {
  user-select: none;
  font-size: 1rem;
  letter-spacing: 0.9px;
  color: var(--color-typography-muted-30);
  padding: 0;
  margin: 0;
}

.widget-content-description {
  line-height: 1.5;
  margin: 0 0 0.625rem;
}

.widget-content-income {
  font-size: 1.5rem;
}
