.sound-settings {
  overflow: visible !important;
  margin: 0.5rem 0;
  min-width: 17rem !important;
  background: var(--color-theme-500);

  .volume-menu-title {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1.5rem;
    color: var(--color-typography-muted-70);
  }

  .volume-menu-item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: 3rem;
    font-weight: 400;
    font-size: 0.875rem;

    // border-top: 1px solid var(--color-theme-400);
    color: var(--color-typography-muted-70);
    transition: background-color 150ms ease, color 150ms ease;

    .sound-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      color: var(--color-typography-muted-70);
      transition: color 150ms ease;
      z-index: 1;

      .sound-number {
        display: flex;
        gap: 0.125rem;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: auto;
        font-size: 1rem;
        font-weight: 700;
        transition: color 150ms ease;

        .sound-level {
          width: auto;
          height: 1.25rem;
          margin: 0;
          color: var(--color-typography-muted-70);
          transition: color 150ms ease;
        }

        span {
          padding-top: 0.1rem;
        }
      }

      &:hover {
        color: var(--color-primary);

        .sound-number {
          .sound-level {
            color: var(--color-primary);
          }
        }
      }
    }

    .sound-preview-btn {
      width: 20%;
      height: 100%;
      padding: 0.5rem;
      background: transparent;
      border: none;
      pointer-events: all;
      z-index: 1;

      .play-icon {
        width: 1rem;
        height: 1rem;
        margin: 0;
        color: var(--color-typography-muted-70);
        transition: color 150ms ease;
      }

      &:hover {
        .play-icon {
          color: var(--color-primary);
        }
      }
    }

    .progress-element {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -100%;
      background: var(--color-theme-400);
      z-index: 0;
      opacity: 0;
      transition: transform 4.5s 150ms linear, opacity 150ms ease;
    }

    &.play {
      .progress-element {
        opacity: 1;
        transform: translateX(100%);
        transition: transform 0, opacity 300ms ease;
      }
    }

    &:disabled {
      background: var(--color-theme-600);
      pointer-events: none;

      .sound-info {
        color: var(--color-typography-muted-30);

        .sound-number,
        .sound-level {
          color: var(--color-typography-muted-30);
        }
      }
    }
  }
}
