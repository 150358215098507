
.mat-menu-revision {
   background: linear-gradient(180.57deg, var(--color-theme-400) -100%, var(--color-theme-500) 100%);

   .mat-menu-content:not(:empty) {
     padding: 0;
     width: 12rem;
     max-height: 12rem;
     display: flex;
     flex-direction: column;
   }

   .mat-menu-item {
     &:hover {
       color: var(--color-primary);
     }
   }
 }
