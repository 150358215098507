$mobile-bp: 480px;
$tablet-bp: 768px;
$desktop-bp: 1024px;
$desktop-tablet-bp: 1280px;
$desktop-wide-bp: 2090px;
$wide-screen-bp: 2500px;

:root{
  --max-content-width-catalogs: 1800px;
  --max-content-width: 1440px;
  --panel-header: 3rem;
}
