// чтобы стилизовать чекбокс просто добавить его класс и переопределить нужные переменные

mat-checkbox {
  --background-color: transparent;
  --accent-color: var(--color-yellow-500);
  --checkmark-color: var(--color-gray-800);
  --border-color: var(--color-gray-800);
  --border-size: 0.125rem;
  --border-hover-size: 0.125rem;
  --size: 1.25rem;
  --label-color: var(--color-gray-300);
  --label-hover-color: var(--color-gray-100);
  --label-link-color: var(--color-blue-500);
  --label-link-hover-color: var(--label-link-color);
  --label-wrap: nowrap;
  --font-size: 1rem;
  --border-radius: calc(var(--size) / 4);

  &.ui-checkbox {
    --border-color: var(--color-gray-700);
    --accent-color: var(--color-blue-500);
    --checkmark-color: var(--color-gray-100);
    --label-wrap: normal;
    --label-color: var(--color-gray-400);
    --label-hover-color: var(--color-gray-100);
    --font-size: 0.875rem;

    &.alert {
      --label-color: var(--color-yellow-500);
      --label-hover-color: var(--label-color);
      --accent-color: var(--label-color);
    }

    .mdc-form-field {
      display: flex;
      align-items: start;

      .mdc-checkbox {
        flex-basis: var(--size);
      }
    }
  }

  &.market-checkbox {
    --border-color: var(--color-gray-100);
    --accent-color: var(--color-blue-500);
    --checkmark-color: var(--color-gray-800);
    --label-wrap: normal;
    --label-color: var(--color-gray-400);
    --label-hover-color: var(--color-gray-100);
    --border-size: 0.0625rem;
    --border-raidus: 0.325rem;
    --size: 1.25rem;

    .mdc-form-field {
      .mdc-checkbox {
        flex-basis: var(--size);
        height: var(--size);
        width: var(--size);
      }
    }
  }

  &.auth-checkbox {
    --border-color: var(--color-blue-500);
    --accent-color: var(--color-blue-500);
    --checkmark-color: var(--color-white-100);
    --label-hover-color: var(--color-gray-100);
    --border-radius: 0.312rem;

    label {
      text-align: left;
      padding-left: 0.75rem;
      line-height: 1.25;

      --font-size: 0.875rem;
    }

    a {
      color: var(--color-blue-500);
    }

    &.alert {
      --label-color: var(--color-gray-400);
      --accent-color: var(--color-blue-500);
      --label-hover-color: var(--color-gray-100);
    }

    .mdc-form-field {
      display: flex;
      align-items: flex-start;
    }

    &.mat-mdc-checkbox-checked {
      .mdc-form-field {
        .mdc-checkbox {
          .mdc-checkbox {
            &__background {
              background: url('/assets/img/mat-icons/common/checkmark.svg') no-repeat center center;
              background-size: contain;

              .mdc-checkbox__checkmark {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.auto-play-menu-checkbox {
    --label-wrap: normal;
  }

  .mdc-form-field {
    line-height: var(--size);
    vertical-align: text-bottom;

    .mdc-checkbox {
      flex-basis: var(--size);
      height: var(--size);
      width: var(--size);
      margin: 0 !important;
      padding: 0 !important;

      .mat-mdc-checkbox-touch-target {
        display: none;
      }

      &__native-control {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
      }

      &__background {
        width: 100%;
        height: 100%;
        background-color: transparent !important;
        border: var(--border-size) solid var(--accent-color) !important;
        top: 0 !important;
        left: 0 !important;
        border-radius: var(--border-radius);
        transition:
          border 150ms ease-in-out,
          background-color 150ms ease-in-out;

        .mdc-checkbox__checkmark {
          color: var(--checkmark-color) !important;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          transition: color 150ms ease-in-out;
        }
      }

      &__ripple {
        width: 100%;
        height: 100%;
      }

      .mat-ripple {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: var(--size);
        height: var(--size);
      }
    }

    label {
      height: auto;
      font-size: var(--font-size);
      color: var(--label-color);
      white-space: var(--label-wrap);
      line-height: 1.5;
      transition: color 150ms ease-in-out;

      .label-text_link {
        font-size: var(--font-size);
        color: var(--label-link-color);
        transition: color 150ms ease-in-out;
      }

      .label-inner {
        display: inline;
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox {
          &__background {
            background-color: var(--accent-color) !important;
            border: var(--border-hover-size) solid var(--accent-color) !important;

            .mdc-checkbox__checkmark {
              color: var(--checkmark-color) !important;
            }
          }
        }
      }
    }
  }

  &:hover {
    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__background {
          border: var(--border-size) solid var(--accent-color) !important;
        }
      }

      label {
        color: var(--label-hover-color);

        .label-text_link {
          color: var(--label-link-hover-color);
        }
      }
    }
  }
}
