mat-radio-button {
  &.languages-list-radio-btn {
    .mdc-radio {
      padding: 0 !important;

      .mat-mdc-radio-touch-target,
      .mdc-radio__native-control {
        width: 1.25rem !important;
        height: 1.25rem !important;
      }

      .mdc-radio__outer-circle {
        border-color: var(--color-blue-500) !important;
      }

      .mdc-radio__inner-circle {
        border-color: var(--color-blue-500) !important;
      }
    }

    label {
      @media screen and (width <= 1024px) {
        padding-left: 0;
      }
    }
  }

  &.countries-list-radio-btn {
    .mdc-radio {
      padding: 0 !important;

      .mat-mdc-radio-touch-target,
      .mdc-radio__native-control {
        width: 1.25rem !important;
        height: 1.25rem !important;
      }

      .mdc-radio__outer-circle {
        border-color: var(--color-blue-500) !important;
      }

      .mdc-radio__inner-circle {
        border-color: var(--color-blue-500) !important;
      }

      .mat-ripple-element {
        background-color: transparent !important;
      }

      .mdc-radio__background::before {
        background-color: transparent !important;
      }
    }

    label {
      padding-left: 0;
    }
  }
}
