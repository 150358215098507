.auth-fail-tooltip,
.auth-fail-tooltip-modal {
  transition: opacity 250ms ease-in-out;

  --tooltip-width: 25rem;

  width: var(--tooltip-width);
  margin-left: calc(-1 * var(--tooltip-width) / 2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  @media screen and (width <=35rem) {
    --tooltip-width: 18rem;
  }
}

.auth-fail-tooltip {
  bottom: 200%;
  left: 50%;
  position: absolute;
}

// legacy
.cdk-overlay-container {
  .mattooltip-theme {
    &-600-primary {
      font-family: RedFont, Roboto;
      background: var(--color-theme-600) !important;
      color: var(--color-primary) !important;
    }

    &-500 {
      font-family: RedFont, Roboto;
      background: var(--color-theme-500) !important;
      color: var(--color-typography-muted-70) !important;
    }
  }

  .auth-checkbox-tooltip {
    position: absolute;
    width: 288px;
    top: -50px;
    left: -150px;
  }

  .case-battle {
    &-create-tooltip {
      margin-left: 2rem;
      padding: 0.75rem !important;
      font-size: 0.875rem !important;
      width: 100vw;
      max-width: 20rem;
    }

    &-join-tooltip {
      margin-left: 1rem;
      font-size: 0.875rem !important;
    }
  }
}

// last
mat-tooltip-component {
  --padding: 0.5rem 1.25rem;
  --background: var(--color-gray-550);
  --color: var(--color-gray-300);
  --left: inherit;
  --border-radius: 0.875rem;
  --tooltip-width: unset;

  text-align: center !important;

  .mdc-tooltip {
    width: var(--tooltip-width);

    &.gray-500 {
      --background: var(--color-gray-500);
      --color: var(--color-gray-150);
    }

    &.gray-550 {
      --background: var(--color-gray-550);
    }

    &.gray-700 {
      --background: var(--color-gray-700);
      --color: var(--color-gray-400);
    }

    &.transaction-tooltip {
      --padding: 1.5rem;
      --tooltip-width: 18rem;

      .mdc-tooltip__surface {
        &::after {
          display: block;
        }
      }
    }

    &.float-tooltip {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 288px;

      --padding: 0.75rem 1rem;
      --border-radius: 0.5rem;

      .mdc-tooltip__surface {
        font-size: 14px;

        &::after {
          display: block;
          width: 1rem;
          height: 0.375rem;
        }
      }
    }

    &.auth-tooltip {
      --border-radius: 0.5rem;
      --background: rgb(97 97 97 / 90%);
      --color: #fff;

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 288px;
      margin-top: -30px;

      .mdc-tooltip__surface {
        font-size: 10px;
        line-height: 0.75rem;
        padding: 0.375rem 1rem;
      }
    }

    &.balance-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 285px;

      --padding: 0.75rem 1rem;
      --border-radius: 0.5rem;

      .mdc-tooltip__surface {
        margin-top: 0.875rem;
        font-weight: 600;
        font-size: 12px;

        &::after {
          display: block;
          top: -14%;
          transform: translateX(-50%) rotate(180deg);
          width: 2rem;
          height: 1rem;
        }
      }
    }

    .mdc-tooltip__surface {
      position: relative;
      width: 100%;
      max-width: none;
      padding: var(--padding);
      background: var(--background);
      color: var(--color);
      border-radius: var(--border-radius);
      overflow: visible;
      white-space: pre-line;
      line-height: 1.75;
      z-index: 1;
      filter: drop-shadow(1px 1px 3px rgb(0 0 0 / 50%));

      &::after {
        content: "";
        position: absolute;
        top: 99%;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 0.75rem;
        background: var(--background);
        z-index: 0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        filter: drop-shadow(1px 1px 3px rgb(0 0 0 / 50%));
        display: none;
      }
    }
  }
}
