
.privacy-card__toggle {
  // need fix
  tui-toggle {
    --width: 2.375rem;
    --height: 1.5rem;

    height: var(--height) !important;
    width: var(--width) !important;

    [tuiWrapper][data-appearance='primary'] {
      background-color: var(--color-blue-500) !important;
    }

    [tuiWrapper][data-appearance='secondary'] {
      background-color: var(--color-gray-500) !important;
    }

    [tuiWrapper] {
      .t-toggle {
        height: var(--height) !important;
        width: calc(var(--width) + 30%) !important;
        transform: translate(-0.75rem) !important;
      }

      .t-circle {
        height: 1.25rem !important;
        width: 1.25rem !important;
        background-color: var(--color-gray-700) !important;
      }
    }
  }

  tui-toggle._checked {
    [tuiWrapper] {
      .t-toggle {
        transform: translate(0) !important;
      }
    }
  }
}
