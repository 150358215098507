app-message-input-v2{
  tui-textarea {
    max-width: 100%;
    border-bottom: 2px solid var(--color-gray-450);
    border-radius: unset !important;

    --tui-textarea-height: 1rem !important;

    .t-input,.t-pseudo-content{
      padding: 0 !important;
    }

    tui-scrollbar{
      border: none !important;
    }

    [tuiWrapper][data-appearance='textfield'] {
      background: transparent;
      color: var(--color-gray-100);
    }

    [tuiWrapper]::after {
      border: none;
    }
  }
}
