@import "../functions";

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {
  font-size: $f-min;

  --base-font-size: $f-min;

  @media (min-width: $w-min) {
    @if  $fallback {
      font-size: $fallback;

      --base-font-size: $fallback;
    }

    --base-font-size: calc-fluid-font-size($f-min, $f-max, $w-min, $w-max, px);

    font-size: var(--base-font-size);
  }

  @media (min-width: $w-max) {
    font-size: $f-max;

    --base-font-size: $f-max;
  }
}
