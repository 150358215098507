.faq-body-wrapper,
.faq-body-wrapper > ul {
  margin-left: 1rem;
  list-style: inside;
}

.lobby-menu-checkbox {
  width: 100%;
  border-radius: 0.3rem;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &--label {
    a {
      color: var(--blue-500, #179be5);
    }
  }

  .mat-checkbox-label {
    color: var(--blue-500, #179be5);
  }

  .mat-checkbox-layout {
    align-items: flex-start;
    gap: 0.75rem;

    .mat-checkbox-inner-container {
      width: 1.25rem;
      height: 1.25rem;
      padding: 1rem;
      margin: 0;

      .mat-checkbox-frame {
        border: none;
      }

      .mat-checkbox-background {
        border-radius: 0.3rem;
        background: var(--blue-500, #179be5) !important;
      }
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        .mat-checkbox-background {
          border-radius: 0.3rem;
          background-color: var(--blue-500, #179be5) !important;

          .mat-checkbox-checkmark-path {
            stroke: var(--blue-500, #179be5) !important;
          }
        }
      }
    }
  }
}

mat-checkbox {
  &.lobby-menu-checkbox {
    width: min-content;

    .mdc-form-field {
      gap: 0.25rem;

      .mdc-checkbox {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0 !important;
        margin: 0 !important;

        .mdc-checkbox__background {
          border-radius: 0.3rem;
          background-color: transparent;
          border: 1px solid var(--blue-500, #179be5) !important;
          top: 0 !important;
          left: 0 !important;

          .mdc-checkbox__checkmark {
            color: white !important;
          }
        }
      }
    }

    &.mat-mdc-checkbox-checked {
      .mdc-form-field {
        .mdc-checkbox {
          width: 1.5rem;
          height: 1.5rem;

          .mdc-checkbox__background {
            border-radius: 0.3rem;
            background-color: var(--blue-500, #179be5) !important;
            border: 1px solid transparent !important;
          }
        }
      }
    }
  }

  &.lobby-menu-checkbox .mdc-form-field label {
    white-space: normal;
  }

  &:hover {
    &.lobby-menu-checkbox {
      .mdc-form-field {
        .mdc-checkbox {
          .mdc-checkbox__background {
            border: 1px solid var(--blue-500, #179be5) !important;
          }
        }
      }
    }
  }
}

.auth-err {
  mat-checkbox {
    .mat-checkbox-layout {
      background: var(--color-attention-error);
    }
  }

  div {
    animation: shake-err 300ms;
    color: var(--color-attention-error) !important;
  }

  .agreement-description a {
    color: var(--color-primary) !important;
  }
}

@keyframes shake-err {
  0% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(-4px);
  }
}

.agreement {
  padding: 2rem;
  display: flex;
  align-items: baseline;
  justify-content: center;

  .agreement-description {
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: var(--typography-gray-200, #757880);

    a {
      color: var(--blue-500, #179be5);
    }
  }
}
