.noUi-horizontal .noUi-handle {
  height: 1.875rem;
  width: 1.875rem;
  top: -0.75rem;
  border-radius: 100%;
  background: var(--color-theme-200);
  border: none;
  box-shadow: none;
  outline: none;
  cursor: grab;

  &::before {
    content: '';
    width: 0.0625rem;
    height: 0.5rem;
    top: 50%;
    left: 50%;
    margin-top: -0.25rem;
    margin-left: -0.3125rem;
    background: var(--color-theme-100-dark);
  }

  &::after {
    content: '';
    width: 0.0625rem;
    height: 0.5rem;
    top: 50%;
    left: 50%;
    margin-top: -0.25rem;
    margin-left: 0.1875rem;
    background: var(--color-theme-100-dark);
  }
}

.noUi-horizontal {
  height: 0.5rem;
  border: none;
}

.noUi-connect {
  background: var(--color-theme-200);
}

.noUi-touch-area {
  &::after {
    content: '';
    position: absolute;
    width: 0.0625rem;
    height: 0.5rem;
    top: 50%;
    left: 50%;
    margin-top: -0.25rem;
    margin-left: -0.0625rem;
    background: var(--color-theme-100-dark);
  }
}

.noUi-target {
  box-shadow: none;
  background: var(--color-theme-700);
}
