.inventory-buttons {
  border-radius: 1.25rem;
  background: var(--color-gray-700);

  button:not(:first-child),
  &_single button {
    height: 3.625rem;
    padding: 0.75rem 2rem;
  }

  mat-spinner {
    --mdc-circular-progress-active-indicator-color: var(--color-yellow-500);
  }

  @media screen and (width <= 48rem) {
    .btn {
      padding: 0.75rem 1rem;
    }

    gap: 0.25rem;
    padding: 0.25rem;
  }

  .inventory-bag-button {
    padding: 1rem;

    @media screen and (width <= 48rem) {
      padding: 0.75rem;
    }

    .inventory-bag {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.inventory-actions-wrapper {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media screen and (width <= 48rem) {
    margin-bottom: 0.25rem;
  }

  button {
    border-radius: 1rem;
  }
}
