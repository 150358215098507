.provably-fair {
  .mat-mdc-tab-body-wrapper {
    background: transparent !important;
  }

  .mat-mdc-tab-labels {
    border-radius: 40px;
    background-color: var(--color-gray-700);
    border: 1px solid var(--color-gray-600);
    padding: .25rem;
    display: inline-flex;
  }

  .mdc-tab {
    height: 36px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2.75rem;
    border-radius: 40px;
    transition: background-color 150ms ease-in-out;
    font-size: 12px;

    &.mdc-tab-indicator--active {
      background-color: var(--color-blue-500);
    }
  }

  .mdc-tab-indicator {
    opacity: 0;
  }

  .mdc-tab__content {
    height: auto;
  }
}
