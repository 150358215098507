/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500';

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  color: rgb(0 0 0 / 87%);
  background: var(--color-primary);
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font:
    400 24px/32px Roboto,
    serif;
  letter-spacing: 0;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  font:
    500 20px/32px Roboto,
    serif;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font:
    400 16px/28px Roboto,
    serif;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
  font:
    500 15px/24px Roboto,
    serif;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography h5 {
  font:
    400 calc(14px * 0.83) / 20px Roboto,
    serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography h6 {
  font:
    400 calc(14px * 0.67) / 20px Roboto,
    serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2 {
  font:
    500 14px/24px Roboto,
    serif;
  letter-spacing: 0.0179em;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font:
    400 14px/20px Roboto,
    serif;
  letter-spacing: 0.0179em;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption {
  font:
    400 12px/20px Roboto,
    serif;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font:
    300 112px/112px Roboto,
    serif;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font:
    400 56px/56px Roboto,
    serif;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font:
    400 45px/48px Roboto,
    serif;
  letter-spacing: 0;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font:
    400 34px/40px Roboto,
    serif;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}

.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
  color: rgb(255 255 255 / 70%);
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px;
  color: white;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font:
    500 20px/32px Roboto,
    serif;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto, serif;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font:
    400 14px/20px Roboto,
    serif;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-weight: 400;
  font-family: 'inherits Roboto', Roboto, serif;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.3438em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.8438em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3438em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:placeholder-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3437em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-label-wrapper {
  top: -0.8438em;
  padding-top: 0.8438em;
}

.mat-form-field-label {
  top: 1.3438em;
  color: rgb(255 255 255 / 70%);
}

.mat-form-field-underline {
  bottom: 1.3438em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6667em;
  top: calc(100% - 1.7917em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.2813em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-form-field-autofill-control:-webkit-autofill
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.2812em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server[label]:not(:placeholder-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  width: 133.3334%;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5417em;
  top: calc(100% - 1.6667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server[label]:not(:placeholder-shown)
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.0938em;
  margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5938em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server[label]:not(:placeholder-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.5937em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.8438em;
  margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5938em) scale(0.75);
  width: 133.3333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server[label]:not(:placeholder-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.5937em) scale(0.75);
  width: 133.3333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text,
.mat-slide-toggle-content {
  line-height: 1.75;
  letter-spacing: 0.92px;
  font-size: 0.75rem;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font:
    500 20px/32px Roboto,
    serif;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
  color: white;
}

.mat-simple-snackbar {
  font-family: Roboto, serif;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition:
    opacity,
    transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
  background-color: rgb(255 255 255 / 10%);
}

@media (-ms-high-contrast: active) {
  .mat-ripple-element {
    display: none;
  }
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1005;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100% !important;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 32%);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgb(250 250 250 / 4%);
}

.mat-option.mat-selected:not(.mat-option-multiple, .mat-option-disabled) {
  background: rgb(250 250 250 / 4%);
}

.mat-option.mat-active {
  background: rgb(250 250 250 / 4%);
  color: white;
}

.mat-option.mat-option-disabled {
  color: rgb(255 255 255 / 50%);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-primary);
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-secondary);
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--color-attention-error);
}

.mat-optgroup-label {
  color: rgb(255 255 255 / 70%);
  font:
    500 14px/24px Roboto,
    serif;
  letter-spacing: 0.0179em;
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgb(255 255 255 / 50%);
}

.mat-pseudo-checkbox {
  color: rgb(255 255 255 / 70%);
}

.mat-pseudo-checkbox::after {
  color: #2c2c2c;
}

.mat-pseudo-checkbox-disabled {
  color: #686868;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--color-secondary);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--color-primary);
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: var(--color-attention-error);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}

.mat-elevation-z0 {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z1 {
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z2 {
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z3 {
  box-shadow:
    0 3px 3px -2px rgb(0 0 0 / 20%),
    0 3px 4px 0 rgb(0 0 0 / 14%),
    0 1px 8px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z4 {
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z5 {
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 5px 8px 0 rgb(0 0 0 / 14%),
    0 1px 14px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z6 {
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z7 {
  box-shadow:
    0 4px 5px -2px rgb(0 0 0 / 20%),
    0 7px 10px 1px rgb(0 0 0 / 14%),
    0 2px 16px 1px rgb(0 0 0 / 12%);
}

.mat-elevation-z8 {
  box-shadow:
    0 5px 5px -3px rgb(0 0 0 / 20%),
    0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}

.mat-elevation-z9 {
  box-shadow:
    0 5px 6px -3px rgb(0 0 0 / 20%),
    0 9px 12px 1px rgb(0 0 0 / 14%),
    0 3px 16px 2px rgb(0 0 0 / 12%);
}

.mat-elevation-z10 {
  box-shadow:
    0 6px 6px -3px rgb(0 0 0 / 20%),
    0 10px 14px 1px rgb(0 0 0 / 14%),
    0 4px 18px 3px rgb(0 0 0 / 12%);
}

.mat-elevation-z11 {
  box-shadow:
    0 6px 7px -4px rgb(0 0 0 / 20%),
    0 11px 15px 1px rgb(0 0 0 / 14%),
    0 4px 20px 3px rgb(0 0 0 / 12%);
}

.mat-elevation-z12 {
  box-shadow:
    0 7px 8px -4px rgb(0 0 0 / 20%),
    0 12px 17px 2px rgb(0 0 0 / 14%),
    0 5px 22px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z13 {
  box-shadow:
    0 7px 8px -4px rgb(0 0 0 / 20%),
    0 13px 19px 2px rgb(0 0 0 / 14%),
    0 5px 24px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z14 {
  box-shadow:
    0 7px 9px -4px rgb(0 0 0 / 20%),
    0 14px 21px 2px rgb(0 0 0 / 14%),
    0 5px 26px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z15 {
  box-shadow:
    0 8px 9px -5px rgb(0 0 0 / 20%),
    0 15px 22px 2px rgb(0 0 0 / 14%),
    0 6px 28px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z16 {
  box-shadow:
    0 8px 10px -5px rgb(0 0 0 / 20%),
    0 16px 24px 2px rgb(0 0 0 / 14%),
    0 6px 30px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z17 {
  box-shadow:
    0 8px 11px -5px rgb(0 0 0 / 20%),
    0 17px 26px 2px rgb(0 0 0 / 14%),
    0 6px 32px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z18 {
  box-shadow:
    0 9px 11px -5px rgb(0 0 0 / 20%),
    0 18px 28px 2px rgb(0 0 0 / 14%),
    0 7px 34px 6px rgb(0 0 0 / 12%);
}

.mat-elevation-z19 {
  box-shadow:
    0 9px 12px -6px rgb(0 0 0 / 20%),
    0 19px 29px 2px rgb(0 0 0 / 14%),
    0 7px 36px 6px rgb(0 0 0 / 12%);
}

.mat-elevation-z20 {
  box-shadow:
    0 10px 13px -6px rgb(0 0 0 / 20%),
    0 20px 31px 3px rgb(0 0 0 / 14%),
    0 8px 38px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z21 {
  box-shadow:
    0 10px 13px -6px rgb(0 0 0 / 20%),
    0 21px 33px 3px rgb(0 0 0 / 14%),
    0 8px 40px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z22 {
  box-shadow:
    0 10px 14px -6px rgb(0 0 0 / 20%),
    0 22px 35px 3px rgb(0 0 0 / 14%),
    0 8px 42px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z23 {
  box-shadow:
    0 11px 14px -7px rgb(0 0 0 / 20%),
    0 23px 36px 3px rgb(0 0 0 / 14%),
    0 9px 44px 8px rgb(0 0 0 / 12%);
}

.mat-elevation-z24 {
  box-shadow:
    0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%),
    0 9px 46px 8px rgb(0 0 0 / 12%);
}

.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: var(--color-theme-600);
  color: white;
}

.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: transparent;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}

@media (-ms-high-contrast: active) {
  .mat-badge-content {
    outline: solid 1px;
    border-radius: 0;
  }
}

.mat-badge-accent .mat-badge-content {
  background: var(--color-secondary);
  color: rgb(0 0 0 / 87%);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: var(--color-attention-error);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgb(255 255 255 / 50%);
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow:
    0 8px 10px -5px rgb(0 0 0 / 20%),
    0 16px 24px 2px rgb(0 0 0 / 14%),
    0 6px 30px 5px rgb(0 0 0 / 12%);
  background: transparent;
  color: white;
  font:
    400 14px/20px Roboto,
    serif;
  letter-spacing: 0.0179em;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: var(--color-primary);
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: var(--color-secondary);
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: var(--color-attention-error);
}

.mat-button.mat-primary[disabled],
.mat-button.mat-accent[disabled],
.mat-button.mat-warn[disabled],
.mat-button[disabled][disabled],
.mat-icon-button.mat-primary[disabled],
.mat-icon-button.mat-accent[disabled],
.mat-icon-button.mat-warn[disabled],
.mat-icon-button[disabled][disabled],
.mat-stroked-button.mat-primary[disabled],
.mat-stroked-button.mat-accent[disabled],
.mat-stroked-button.mat-warn[disabled],
.mat-stroked-button[disabled][disabled] {
  color: rgb(255 255 255 / 30%);
}

.mat-button.mat-primary .mat-button-focus-overlay,
.mat-icon-button.mat-primary .mat-button-focus-overlay,
.mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--color-primary);
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--color-secondary);
}

.mat-button.mat-warn .mat-button-focus-overlay,
.mat-icon-button.mat-warn .mat-button-focus-overlay,
.mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: var(--color-attention-error);
}

.mat-button[disabled] .mat-button-focus-overlay,
.mat-icon-button[disabled] .mat-button-focus-overlay,
.mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button .mat-ripple-element,
.mat-icon-button .mat-ripple-element,
.mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentcolor;
}

.mat-button-focus-overlay {
  background: #fff;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgb(255 255 255 / 12%);
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: white;
  background-color: transparent;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  color: white;
  background-color: var(--color-attention-error);
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--color-primary);
  color: rgb(0 0 0 / 87%);
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: var(--color-secondary);
  color: rgb(0 0 0 / 87%);
}

.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
  background-color: rgb(250 250 250 / 12%);
  color: rgb(255 255 255 / 30%);
}

.mat-flat-button.mat-primary .mat-ripple-element,
.mat-raised-button.mat-primary .mat-ripple-element,
.mat-fab.mat-primary .mat-ripple-element,
.mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgb(0 0 0 / 10%);
}

.mat-flat-button.mat-accent .mat-ripple-element,
.mat-raised-button.mat-accent .mat-ripple-element,
.mat-fab.mat-accent .mat-ripple-element,
.mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgb(0 0 0 / 10%);
}

.mat-flat-button.mat-warn .mat-ripple-element,
.mat-raised-button.mat-warn .mat-ripple-element,
.mat-fab.mat-warn .mat-ripple-element,
.mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgb(255 255 255 / 10%);
}

.mat-stroked-button:not([class*='mat-elevation-z']),
.mat-flat-button:not([class*='mat-elevation-z']) {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-raised-button:not([class*='mat-elevation-z']) {
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow:
    0 5px 5px -3px rgb(0 0 0 / 20%),
    0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}

.mat-raised-button[disabled]:not([class*='mat-elevation-z']) {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-fab:not([class*='mat-elevation-z']),
.mat-mini-fab:not([class*='mat-elevation-z']) {
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}

.mat-fab:not([disabled]):active:not([class*='mat-elevation-z']),
.mat-mini-fab:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow:
    0 7px 8px -4px rgb(0 0 0 / 20%),
    0 12px 17px 2px rgb(0 0 0 / 14%),
    0 5px 22px 4px rgb(0 0 0 / 12%);
}

.mat-fab[disabled]:not([class*='mat-elevation-z']),
.mat-mini-fab[disabled]:not([class*='mat-elevation-z']) {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-button-toggle {
  color: rgb(255 255 255 / 50%);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgb(255 255 255 / 12%);
}

.mat-button-toggle-appearance-standard {
  color: white;
  background: transparent;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgb(255 255 255 / 12%);
}

[dir='rtl'] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgb(255 255 255 / 12%);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgb(255 255 255 / 12%);
}

.mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgb(255 255 255 / 70%);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}

.mat-button-toggle-disabled {
  color: rgb(255 255 255 / 30%);
  background-color: #464646;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: transparent;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgb(255 255 255 / 12%);
  box-shadow: none;
}

.mat-card {
  background: transparent;
  color: white;
}

.mat-card:not([class*='mat-elevation-z']) {
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}

.mat-card.mat-card-flat:not([class*='mat-elevation-z']) {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-card-subtitle {
  color: rgb(255 255 255 / 70%);
}

.mat-checkbox-frame {
  border-color: rgb(255 255 255 / 70%);
}

.mat-checkbox-checkmark {
  fill: #2c2c2c;
}

.mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}

@media (-ms-high-contrast: black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important;
  }
}

.mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-secondary);
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: var(--color-attention-error);
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgb(255 255 255 / 70%);
}

@media (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5;
  }
}

@media (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none;
  }
}

.mat-checkbox .mat-ripple-element {
  background-color: #fff;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--color-primary);
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--color-secondary);
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: var(--color-attention-error);
}

.mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow:
    0 3px 3px -2px rgb(0 0 0 / 20%),
    0 3px 4px 0 rgb(0 0 0 / 14%),
    0 1px 8px 0 rgb(0 0 0 / 12%);
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}

.mat-chip.mat-standard-chip::after {
  background: #fff;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--color-primary);
  color: rgb(0 0 0 / 87%);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgb(0 0 0 / 87%);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background: rgb(0 0 0 / 10%);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: var(--color-attention-error);
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background: rgb(255 255 255 / 10%);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--color-secondary);
  color: rgb(0 0 0 / 87%);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgb(0 0 0 / 87%);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background: rgb(0 0 0 / 10%);
}

.mat-table {
  background: transparent;
}

.mat-table thead,
.mat-table tbody,
.mat-table tfoot,
mat-header-row,
mat-row,
mat-footer-row,
[mat-header-row],
[mat-row],
[mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgb(255 255 255 / 12%);
}

.mat-calendar-arrow {
  border-top-color: white;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}

.mat-calendar-table-header {
  color: rgb(255 255 255 / 50%);
}

.mat-calendar-table-header-divider::after {
  background: rgb(255 255 255 / 12%);
}

.mat-calendar-body-label {
  color: rgb(255 255 255 / 70%);
}

.mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgb(255 255 255 / 50%);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgb(250 250 250 / 4%);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgb(255 255 255 / 50%);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgb(255 255 255 / 30%);
}

.mat-calendar-body-selected {
  background-color: var(--color-primary);
  color: rgb(0 0 0 / 87%);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgb(246 228 68 / 40%);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 87%);
}

.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  background-color: transparent;
  color: white;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--color-secondary);
  color: rgb(0 0 0 / 87%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgb(0 186 217 / 40%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 87%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: var(--color-attention-error);
  color: white;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgb(240 82 40 / 40%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 20%),
    0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-datepicker-toggle-active {
  color: var(--color-primary);
}

.mat-datepicker-toggle-active.mat-accent {
  color: var(--color-secondary);
}

.mat-datepicker-toggle-active.mat-warn {
  color: var(--color-attention-error);
}

.mat-dialog-container {
  display: flex !important;
  justify-content: center;
  box-shadow:
    0 11px 15px -7px rgb(0 0 0 / 20%),
    0 24px 38px 3px rgb(0 0 0 / 14%),
    0 9px 46px 8px rgb(0 0 0 / 12%);
  background: none;
  color: white;
  overflow: visible !important;
}

.mat-divider {
  border-top-color: rgb(255 255 255 / 12%);
}

.mat-divider-vertical {
  border-right-color: rgb(255 255 255 / 12%);
}

.mat-expansion-panel {
  background: transparent;
  color: white;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-action-row {
  border-top-color: rgb(255 255 255 / 12%);
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgb(250 250 250 / 4%);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded, [aria-disabled='true']) .mat-expansion-panel-header:hover {
    background: transparent;
  }
}

.mat-expansion-panel-header-title {
  color: white;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgb(255 255 255 / 70%);
}

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgb(255 255 255 / 30%);
}

.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-hint {
  color: rgb(255 255 255 / 70%);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-primary);
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--color-secondary);
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: var(--color-attention-error);
}

.mat-focused .mat-form-field-required-marker {
  color: var(--color-secondary);
}

.mat-form-field-ripple {
  background-color: white;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--color-primary);
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: var(--color-secondary);
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: var(--color-attention-error);
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--color-primary);
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: var(--color-secondary);
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: var(--color-attention-error);
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--color-attention-error);
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: var(--color-attention-error);
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: var(--color-attention-error);
}

.mat-error {
  color: var(--color-attention-error);
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.2813em;
}

.mat-form-field-appearance-legacy .mat-hint {
  color: rgb(255 255 255 / 70%);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgb(255 255 255 / 70%);
  bottom: 1.25em;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgb(255 255 255 / 70%) 0%, rgb(255 255 255 / 70%) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgb(255 255 255 / 70%);
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgb(255 255 255 / 70%) 0%, rgb(255 255 255 / 70%) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: var(--color-theme-600);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgb(255 255 255 / 5%);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgb(255 255 255 / 50%);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgb(255 255 255 / 50%);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0.5em !important;
  color: rgb(255 255 255 / 30%);
}

.mat-select-arrow-wrapper {
  vertical-align: baseline !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--color-primary);
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--color-secondary);
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: var(--color-attention-error);
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--color-attention-error);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgb(255 255 255 / 50%);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgb(255 255 255 / 15%);
}

.mat-icon.mat-primary {
  color: var(--color-primary);
}

.mat-icon.mat-accent {
  color: var(--color-secondary);
}

.mat-icon.mat-warn {
  color: var(--color-attention-error);
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgb(255 255 255 / 70%);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgb(255 255 255 / 50%);
}

.mat-input-element {
  caret-color: var(--color-primary);
}

.mat-input-element::placeholder {
  color: rgb(255 255 255 / 50%);
}

.matelement::placeholder {
  color: rgb(255 255 255 / 50%);
}

.mat-input-element option {
  color: rgb(0 0 0 / 87%);
}

.mat-input-element option:disabled {
  color: rgb(0 0 0 / 38%);
}

.mat-accent .mat-input-element {
  caret-color: var(--color-secondary);
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: var(--color-attention-error);
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: var(--color-attention-error);
}

.mat-list-base .mat-list-item {
  color: white;
  font-size: 16px;
}

.mat-list-base .mat-list-option {
  color: white;
  font-size: 16px;
}

.mat-list-base .mat-subheader {
  color: rgb(255 255 255 / 70%);
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-item-disabled {
  background-color: #464646;
}

.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgb(250 250 250 / 4%);
}

.mat-menu-panel {
  background: transparent;
}

.mat-menu-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-menu-item {
  background: transparent;
  color: white;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-menu-item[disabled],
.mat-menu-item[disabled]::after {
  color: rgb(255 255 255 / 50%);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: white;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgb(250 250 250 / 4%);
}

.mat-paginator {
  background: transparent;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgb(255 255 255 / 70%);
  font-family: Roboto, serif;
  font-size: 12px;
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid white;
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgb(255 255 255 / 50%);
}

.mat-progress-bar-background {
  fill: #fcf7c7;
}

.mat-progress-bar-buffer {
  background-color: #fcf7c7;
}

.mat-progress-bar-fill::after {
  background-color: var(--color-primary);
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3eaf4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3eaf4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--color-secondary);
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbcbbf;
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbcbbf;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: var(--color-attention-error);
}

.mat-radio-outer-circle {
  border-color: rgb(255 255 255 / 70%);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary);
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--color-primary);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-secondary);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-secondary);
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-attention-error);
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: var(--color-attention-error);
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgb(255 255 255 / 50%);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgb(255 255 255 / 50%);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgb(255 255 255 / 50%);
}

.mat-radio-button .mat-ripple-element {
  background-color: #fff;
}

.mat-select-value {
  color: white;
}

.mat-select-placeholder {
  color: rgb(255 255 255 / 50%);
}

.mat-select-disabled .mat-select-value {
  color: rgb(255 255 255 / 50%);
}

.mat-select-arrow {
  color: rgb(255 255 255 / 70%);
}

.mat-select-panel {
  background: var(--color-theme-600);
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgb(250 250 250 / 12%);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--color-primary);
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--color-secondary);
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: var(--color-attention-error);
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: var(--color-attention-error);
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgb(255 255 255 / 50%);
}

.mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}

.mat-drawer {
  background-color: transparent;
  color: white;
}

.mat-drawer.mat-drawer-push {
  background-color: transparent;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow:
    0 8px 10px -5px rgb(0 0 0 / 20%),
    0 16px 24px 2px rgb(0 0 0 / 14%),
    0 6px 30px 5px rgb(0 0 0 / 12%);
}

.mat-drawer-side {
  border-right: solid 1px rgb(255 255 255 / 12%);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgb(255 255 255 / 12%);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side {
  border-left: solid 1px rgb(255 255 255 / 12%);
  border-right: none;
}

[dir='rtl'] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgb(255 255 255 / 12%);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgb(198 198 198 / 60%);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-secondary);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 186 217 / 54%);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--color-secondary);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-primary);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-theme-800);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--color-primary);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-attention-error);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(240 82 40 / 54%);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: var(--color-attention-error);
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}

.mat-slide-toggle-thumb {
  box-shadow:
    0 2px 1px -1px rgb(0 0 0 / 20%),
    0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  background-color: var(--color-typography-muted-30);
}

.mat-slide-toggle-bar {
  background-color: var(--color-theme-800);
}

.mat-slider-track-background {
  background-color: rgb(255 255 255 / 30%);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: var(--color-primary);
}

.mat-primary .mat-slider-thumb-label-text {
  color: rgb(0 0 0 / 87%);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: var(--color-secondary);
}

.mat-accent .mat-slider-thumb-label-text {
  color: rgb(0 0 0 / 87%);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: var(--color-attention-error);
}

.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgb(0 186 217 / 20%);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgb(255 255 255 / 30%);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgb(255 255 255 / 30%);
}

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgb(255 255 255 / 30%);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgb(255 255 255 / 12%);
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgb(255 255 255 / 30%);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgb(255 255 255 / 30%);
  background-color: var(--color-theme-800);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgb(255 255 255 / 30%);
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,
.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgb(255 255 255 / 30%);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgb(255 255 255 / 70%);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(0.0001deg, rgb(255 255 255 / 70%), rgb(255 255 255 / 70%) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgb(255 255 255 / 70%), rgb(255 255 255 / 70%) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: rgb(250 250 250 / 4%);
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgb(255 255 255 / 70%);
}

.mat-step-header .mat-step-icon {
  background-color: rgb(255 255 255 / 70%);
  color: rgb(0 0 0 / 87%);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--color-primary);
  color: rgb(0 0 0 / 87%);
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: var(--color-attention-error);
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: var(--color-attention-error);
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgb(255 255 255 / 12%);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgb(255 255 255 / 12%);
}

.mat-sort-header-arrow {
  color: #c4c4c4;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgb(255 255 255 / 12%);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgb(255 255 255 / 12%);
  border-bottom: none;
}

.mat-tab-label,
.mat-tab-link {
  color: white;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: rgb(255 255 255 / 50%);
}

.mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgb(255 255 255 / 50%);
}

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(252 247 199 / 30%);
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--color-primary);
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(179 234 244 / 30%);
}

.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--color-secondary);
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(251 203 191 / 30%);
}

.mat-tab-group.mat-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: var(--color-attention-error);
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(252 247 199 / 30%);
}

.mat-tab-group.mat-background-primary .mat-tab-header,
.mat-tab-group.mat-background-primary .mat-tab-links,
.mat-tab-group.mat-background-primary .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary .mat-tab-links,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: var(--color-primary);
}

.mat-tab-group.mat-background-primary .mat-tab-label,
.mat-tab-group.mat-background-primary .mat-tab-link,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgb(0 0 0 / 40%);
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgb(0 0 0 / 40%);
}

.mat-tab-group.mat-background-primary .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgb(0 0 0 / 12%);
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(179 234 244 / 30%);
}

.mat-tab-group.mat-background-accent .mat-tab-header,
.mat-tab-group.mat-background-accent .mat-tab-links,
.mat-tab-group.mat-background-accent .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent .mat-tab-links,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: var(--color-secondary);
}

.mat-tab-group.mat-background-accent .mat-tab-label,
.mat-tab-group.mat-background-accent .mat-tab-link,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgb(0 0 0 / 40%);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: rgb(0 0 0 / 87%);
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgb(0 0 0 / 40%);
}

.mat-tab-group.mat-background-accent .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgb(0 0 0 / 12%);
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgb(251 203 191 / 30%);
}

.mat-tab-group.mat-background-warn .mat-tab-header,
.mat-tab-group.mat-background-warn .mat-tab-links,
.mat-tab-group.mat-background-warn .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn .mat-tab-links,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: var(--color-attention-error);
}

.mat-tab-group.mat-background-warn .mat-tab-label,
.mat-tab-group.mat-background-warn .mat-tab-link,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgb(255 255 255 / 40%);
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgb(255 255 255 / 40%);
}

.mat-tab-group.mat-background-warn .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgb(255 255 255 / 12%);
}

.mat-toolbar {
  background: transparent;
  color: white;
}

.mat-toolbar.mat-primary {
  background: var(--color-primary);
  color: rgb(0 0 0 / 87%);
}

.mat-toolbar.mat-accent {
  background: var(--color-secondary);
  color: rgb(0 0 0 / 87%);
}

.mat-toolbar.mat-warn {
  background: var(--color-attention-error);
  color: white;
}

.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentcolor;
}

.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-input-element {
  caret-color: currentcolor;
}

.mat-tooltip {
  background: rgb(97 97 97 / 90%);
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tree {
  background: transparent;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.mat-snack-bar-container {
  color: rgb(0 0 0 / 87%);
  background: #fafafa;
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}

.mat-simple-snackbar-action {
  color: inherit;
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

// Переопределение стилей после обновления material до 17ой версии
.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0);
    --mdc-filled-text-field-disabled-container-color: rgba(0, 0, 0, 0);
  }
}

.mat-mdc-tab-nav-bar {
  .mat-mdc-tab-header-pagination {
    --mat-tab-header-pagination-icon-color: white;
  }

  .mat-mdc-tab-link-container {
    .mat-mdc-tab-list {
      .mat-mdc-tab-links {
        .mat-mdc-tab-link {
          .mdc-tab__ripple {
            border-radius: inherit;

            &::before {
              border-radius: inherit;
            }
          }
        }
      }
    }
  }
}

.mdc-dialog {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      background-color: unset;
      box-shadow: none;
      overflow: unset;
    }
  }
}

.mdc-slider {
  .mdc-slider__thumb {
    .mdc-slider__value-indicator-container {
      .mdc-slider__value-indicator {
        --mat-slider-value-indicator-opacity: 1;
      }
    }
  }
}
