label.radio-button {
  flex: 0 0 128px;
  cursor: pointer;
  position: relative;
  height: 32px;

  &:hover {
    span {
      background-color: var(--color-theme-200);
      text-decoration: none;
    }
  }

  &:first-child {
    span {
      margin-left: 0;
    }
  }

  &:last-child {
    span {
      margin-right: 0;
    }
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + span {
    color: var(--color-typography-light);
    background-color: var(--color-theme-800-dark);
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-theme-600);
    border-radius: 4px;
    border-color: transparent;
    outline: none;
    transition: background-color 0.15s ease-in;
    text-decoration: none;
    color: var(--color-typography-muted-30);
    font-size: 14px;
    letter-spacing: 0.96px;
    text-align: center;
  }
}

.sort-selector {
  padding: 0 8px;
  font-size: 12px;
  letter-spacing: 0.89px;
  background-color: var(--color-theme-800);
  border-color: var(--color-theme-800);

  &:hover {
    background-color: var(--color-theme-600);
    border-color: var(--color-theme-600);
  }

  &-direction {
    &.rotate {
      transform: rotate(180deg);
    }

    &.hide {
      visibility: hidden;
    }
  }

  &-container {
    input[type='radio'] {
      display: none;
    }

    input[type='radio']:checked + label {
      color: var(--color-secondary);
    }
  }
}

.icon-arrow-left::before {
  content: '\e92e';
}

.icon-arrow-right::before {
  content: '\e92f';
}

.icon-arrow-up::before {
  content: '\e92d';
}
