mat-slider {
  // mat-slider v15<
  --thumb-point-size: 1rem;
  --track-height: 0.125rem;
  --track-color: var(--color-yellow-400);
  --track-background: var(--color-gray-550);
  --track-hover-background: var(--color-gray-500);

  height: var(--thumb-point-size) !important;

  // &.ui-slider {}

  padding: 0 !important;
  width: calc(100% - (var(--thumb-point-size) / 4)) !important;

  .mat-slider {
    &-wrapper {
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    &-wrapper,
    &-track-wrapper,
    &-track-background,
    &-track-fill {
      height: var(--track-height) !important;
    }

    &-wrapper,
    &-track-wrapper {
      border-radius: var(--track-height);
    }

    &-track-background {
      background-color: var(--track-background) !important;
      transition: background-color 150ms ease-in-out;
    }

    &-track-fill,
    &-thumb,
    &-thumb-label {
      background: var(--track-color) !important;
      border: none;
    }

    &-thumb {
      inset: 50% 0 0 100%;
      transform: translate(-50%, -50%) !important;
      width: var(--thumb-point-size);
      height: var(--thumb-point-size);
    }
  }

  &:hover,
  &.cdk-focused {
    .mat-slider-track-background {
      background-color: var(--track-hover-background) !important;
    }

    .mat-slider-thumb {
      transform: translate(-50%, -50%) scale(1.25) !important;
    }
  }

  &.cdk-focused {
    .mat-slider-thumb {
      transform: translate(-50%, -50%) scale(1.25) !important;

      &-label {
        display: flex;
        transform: none !important;
        border-radius: 0.25rem !important;
        width: 0;
        height: 0;
        padding: 0;
        top: 0;
        right: 0;

        &-text {
          font-family: RedFont, Roboto, sans-serif;
          white-space: nowrap;
          letter-spacing: 0;
          color: var(--color-gray-700) !important;
          background-color: var(--track-color) !important;
          border-radius: 0.5rem;
          padding: 0.25rem 0.5rem;
          transform: translateY(calc(-50% - var(--thumb-point-size))) !important;
        }
      }
    }
  }

  // mat-slider v15+
  --mdc-slider-active-track-color: theme('colors.yellow.500');
  --mdc-slider-inactive-track-color: theme('colors.gray.550');
  --mdc-slider-active-track-height: 2px;
  --mdc-slider-handle-width: 1rem;
  --mdc-slider-handle-height: 1rem;
  --mdc-slider-handle-color: var(--color-yellow-500);
  --mdc-slider-focus-handle-color: var(--color-yellow-500);
  --mdc-slider-hover-handle-color: var(--color-yellow-500);
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-weight: 500;

  .mdc-slider__thumb {
    left: calc(var(--mdc-slider-handle-width) / 2 * -1) !important;
    width: var(--mdc-slider-handle-width) !important;
    height: var(--mdc-slider-handle-height) !important;
  }

  &.filter-slider {
    .mdc-slider__thumb.mat-mdc-slider-visual-thumb:last-child {
      .mdc-slider__value-indicator-container {
        top: 0.75rem;
      }

      .mdc-slider__value-indicator::before {
        display: none;
      }
    }
  }

  &.p2p-setup-slider {
    .mdc-slider__thumb.mat-mdc-slider-visual-thumb.mdc-slider__thumb--top {
      z-index: 0 !important;
    }

    .mdc-slider__value-indicator {
      transform: scale(1) !important;
      white-space: nowrap;

      --mdc-slider-label-container-color: none;
    }

    .mdc-slider__value-indicator-container {
      bottom: 0.75rem;
    }
  }

  &.withdrawal-slider {
    padding: 0 0.5rem !important;
    margin: 0;
    width: calc(100% - 1rem) !important;

    // input {}

    .mdc-slider__track {
      width: calc(100% + 1rem);
      left: -0.5rem;
    }

    .mdc-slider__value-indicator {
      --mdc-slider-label-container-color: none;

      color: var(--color-yellow-500);
      white-space: nowrap;

      &::after,
      &::before {
        display: none !important;
      }

      &-text {
        // eslint-disable-next-line
        font-family: RedFont, Roboto;
      }
    }

    .mdc-slider__value-indicator-container {
      height: 1rem;
      transform: var(--slider-value-indicator-container-transform, translate(-50%, 75%));
    }
  }
}
