.currency-format-directive {
  --gap: 0.5rem;
  --font-size: 1rem;
  --value-font-weight: 700;
  --value-color: var(--color-gray-100);
  --fraction-font-weight: 400;
  --fraction-color: var(--value-color);
  --icon-width: 1rem;
  --icon-height: 1rem;
  --icon-color: var(--value-color);

  font-weight: var(--value-font-weight);
  color: var(--value-color);
  display: flex;
  align-items: center;

  mat-icon {
    width: var(--icon-width);
    height: var(--icon-height);
    margin-right: var(--gap);
    color: var(--icon-color);
  }

  &__fraction {
    font-weight: var(--fraction-font-weight);
    color: var(--fraction-color);
  }
}
