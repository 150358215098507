// @import '~projects/assets/styles/variables';

$color-overlay: rgb(0 0 0 / 80%);

app-routable-modal-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: $z-index-modal;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop,
.cdk-overlay-pane {
  &.blurred {
    backdrop-filter: blur(0.5rem) !important;

    &.transparent {
      background: var(--color-gray-750-transparent) !important;
    }

    &.translucent {
      background: var(--color-gray-750-translucent) !important;
    }
  }

  &.clear {
    background: none !important;
    backdrop-filter: none !important;
  }
}

.common-routable-modal {
  // width: auto !important;
  // height: auto !important;
  // max-width: 70rem !important;

  .mat-dialog-container {
    overflow: unset;
  }
}

.no-cross {
  .nsm-dialog-btn-close {
    display: none;
  }
}

.nsm-dialog {
  max-width: 100%;
}

.md {
  .nsm-content {
    max-width: 490px !important;
  }
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 660px;
  pointer-events: auto;
  background-color: var(--color-theme-600);
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  margin: 6rem auto;
}

.modal-header {
  border-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.mat-dialog-container {
  user-select: none;
  padding: 0 !important;
  box-shadow: none;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 62%);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.user-profile-modal {
  .mat-dialog-container {
    border-radius: 16px;
    overflow: visible !important;
    display: flex;
    justify-content: center;
    user-select: auto;
  }

  // max-width: 69rem !important;

  // @media screen and (width <=1024px) {
  //   max-width: 80vw !important;
  //   min-width: 46rem;
  // }

  // @media screen and (width <=768px) {
  //   max-width: 90vw !important;
  //   min-width: auto;
  // }

  // @media screen and (width <=480px) {
  //   max-width: 100vw !important;
  //   min-width: auto;
  // }
}

.user-edit-modal {
  border-radius: 16px !important;
  background: var(--color-gray-600);

  @media screen and (width <=480px) {
    height: 100% !important;
    width: 100% !important;
  }
}

.crop-modal {
  @media screen and (width <=480px) {
    height: 100% !important;
    width: 100% !important;
  }
}

.select-country-modal {
  @media screen and (width <=480px) {
    height: auto !important;
    width: 100% !important;
  }
}

.gleam-modal {
  .mat-dialog-container {
    border-radius: 8px;
    padding: 0;
  }
}

.ban-modal {
  .mat-dialog-container {
    border-radius: 8px;
    background-color: var(--color-theme-400);
  }
}

.referral-modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 62%);

  @keyframes spin-shine {
    100% {
      transform: rotate(360deg);
    }
  }

  &::after {
    content: '';
    animation: spin-shine 16s linear infinite;
    background: var(--shine-background);
    height: 65rem;
    width: 65rem;
  }
}

.confirm-dialog,
.tech-support-modal,
.lottery-rules-modal,
.user-profile-modal,
.revision-modal,
.case-battle-account-type,
.case-battle-create-faq,
.case-battle-winners,
.case-info-modal,
.ddos-modal,
.ban-modal,
.client-seed-history-modal,
.server-seed-history-modal,
.auth-modal,
.auth-modal-v2,
.p2p-auction-completed-modal,
.p2p-kits-details-modal,
.p2p-kits-details-new-modal,
.p2p-trade-accept,
.p2p-setup-modal {
  .mat-dialog-container {
    padding: 0;
  }
}

.p2p-setup-modal {
  .mat-dialog-container {
    border-radius: 1rem;
  }
}

.auth-modal-v2 {
  .mat-dialog-container {
    background: none;
    box-shadow: none;
  }
}

$breakpoints: (
  's': 500px,
  'm': 768px,
  'l': 1024px,
);

@each $name, $width in $breakpoints {
  .mobile-modal-modificator-#{$name} {
    @media screen and (width <= $width) {
      position: fixed !important;
      bottom: 0;
      width: 100vw !important;
    }
  }
}

.revision-modal {
  width: 100%;
  height: auto;
  max-width: 50rem !important;
  max-height: clamp(0px, 60rem, 100vh) !important;
}

.confirm-dialog {
  position: relative !important;

  .mat-dialog-container {
    border-radius: 6px;
    overflow: unset;
  }
}

.cases-autoplay-game {
  .mat-dialog-container {
    width: calc(100vw - 8rem);
    overflow: inherit;
    max-width: 50rem;
    padding: 0;
  }
}

.case-battle-provably-modal {
  width: 100%;
  height: 100%;
  max-width: 50rem !important;
  max-height: 40rem !important;
  padding: 0.25rem;
}

.case-battle-game-break {
  .mat-dialog-container {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.steam-error-modal {
  .mat-dialog-container {
    overflow: visible;
    padding: 0;
    border-radius: 0.5rem 0;
  }
}

.classic-victory-modal-panel {
  .mat-dialog-container {
    border-radius: 16px;
    overflow: visible !important;
    display: flex;
    justify-content: center;
    user-select: auto;
  }

  width: 100%;
  max-width: 69rem !important;

  @media screen and (width <=1024px) {
    width: 42rem;
  }

  @media screen and (width <=768px) {
    width: 32rem;
  }

  @media screen and (width <=480px) {
    max-width: 100vw !important;
    min-width: auto;
  }
}

.classic-fair-game-modal-backdrop,
.classic-victory-modal-backdrop {
  backdrop-filter: blur(0.5rem);
  background-color: rgb(0 0 0 / 80%);
}
