/* stylelint-disable */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;


@include mat.all-component-typographies;
@include mat.core;


$gofast-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$gofast-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gofast-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gofast-app-theme: mat.m2-define-light-theme((color: (primary: $gofast-app-primary,
        accent: $gofast-app-accent,
        warn: $gofast-app-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gofast-app-theme);
@import 'styles-renew/colors';
@import 'styles-market/style-base';
@import 'styles-common/style-base';
@import 'styles-renew/style-base';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;

}

.loading-gradient {
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #606975 8%, #dbf5fd 18%, #606975 33%);
  background-size: 800px 104px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}