.email-confirmation {
  mat-checkbox {
    &.filter-checkbox,
    &.auto-play-menu-checkbox {
      width: min-content;

      .mdc-form-field {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        .mdc-checkbox {
          width: 1.125rem !important;
          height: 1.125rem !important;
          padding: 0 !important;
          margin: 0 !important;

          .mdc-checkbox__background {
            background-color: var(--color-theme-600) !important;
            border: 1px solid var(--color-theme-500) !important;
            top: 0 !important;
            left: 0 !important;

            .mdc-checkbox__checkmark {
              color: var(--color-typography-light) !important;
            }
          }
        }

        label {
          white-space: nowrap;
        }
      }

      &.mat-mdc-checkbox-checked {
        .mdc-form-field {
          .mdc-checkbox {
            width: 1.5rem;
            height: 1.5rem;

            .mdc-checkbox__background {
              background-color: var(--color-blue-500) !important;
              border: 1px solid transparent !important;
            }
          }
        }
      }
    }

    &.auto-play-menu-checkbox .mdc-form-field label {
      white-space: normal;
      font-size: .875rem;
      line-height: 1.25rem;
    }

    &:hover {
      &.filter-checkbox {
        .mdc-form-field {
          .mdc-checkbox {
            .mdc-checkbox__background {
              border: 1px solid var(--color-blue-500) !important;
            }
          }
        }
      }
    }
  }
}
