.ps--active-y > .ps__rail-y {
  background-color: transparent !important;
}

.ps__rail-y {
  z-index: 10;
}

.ps__thumb-y {
  background-color: var(--color-theme-300) !important;
}

.ps__rail-x,
.ps__rail-x > .ps__thumb-x,
.ps__rail-y,
.ps__rail-y > .ps__thumb-y {
  width: 0.375rem;
  right: 0.125rem;

  &:active,
  &:focus {
    outline: none !important;
  }
}

perfect-scrollbar > .ps.ps--active-y > .ps__rail-y {
  width: 0.625rem;
}

app-desktop-left-sidebar,
app-ui-expanded-area,
app-ui-mobile-menu {
  .ps__thumb-y,
  .ps__rail-y {
    width: 0 !important;
  }
}

.case-battle-game-srollbar {
  --scrollbar-thumb-color: var(--color-theme-400);

  .ng-scroll-content {
    padding-bottom: 0 !important;
  }
}

.case-battle-modal-srollbar {
  --scrollbar-thumb-color: var(--color-theme-500);

  .ng-scroll-content {
    padding-right: 0 !important;
  }
}

.last-games--mobile{
  .ng-scroll-content{
    height: 100%;
  }
}

app-case-battle-game {
  perfect-scrollbar {
    .ps {
      .ps-overlay {
        right: 1rem;
      }
    }
  }

  .case-battle-scrollbar-track {
    display: flex;
    align-items: center;
    overflow: visible !important;
  }

  .case-battle-scrollbar-thumb {
    height: 1.25rem !important;

    &::after {
      content: '||||';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-typography-muted-30);
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 1px;
      filter: blur(0.2px);
    }
  }
}

.hidden-scrollbar {
  .ng-scrollbar-wrapper[deactivated='false'] > scrollbar-x.scrollbar-control {
    opacity: 0;
  }
}

.history-scrollbar {
  div {
    scrollbar-y {
      margin-top: 2.5rem;
    }
  }
}