.account {
  .mat-mdc-tab-links {
    display: inline-flex;
    gap: .625rem;
  }

  .mat-mdc-tab-link.mdc-tab {
    height: 36px;
  }
}
