.participation-panel-filter-controls {
  color: var(--color-gray-400);
  font-size: 0.75rem;

  app-currency {
    --font-size: 0.75rem;
  }

  .control__btn {
    padding: 0.5rem 0.75rem;

    &:hover {
      background: var(--color-gray-600);
    }
  }

  &.participation-panel-filter__dropdown {
    background: var(--color-gray-700);

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.mat-menu--cases-filter {
  --mat-menu-container-color: rgb(0 0 0 / 0%);
  --mat-menu-item-label-text-color: white;

  .mat-mdc-menu-content {
    padding: 0;
    background: var(--color-gray-700);

    .mat-mdc-menu-item {
      &:hover {
        background: var(--color-gray-600);
      }
    }
  }
}
