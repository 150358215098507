.mt-a {
  margin-top: auto;
}

.mb-a {
  margin-bottom: auto;
}

.m-a {
  margin: auto;
}

@for $i from 0 through 10 {
  //.5rem step
  .m-#{$i} {
    margin: $i * 0.5rem;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.5rem;
  }
  .mt-#{$i} {
    margin-top: $i * 0.5rem;
  }

  //0.25rem step
  .m-sm-#{$i} {
    margin: $i * 0.25rem;
  }
  .mb-sm-#{$i} {
    margin-bottom: $i * 0.25rem;
  }
  .mt-sm-#{$i} {
    margin-top: $i * 0.25rem;
  }
 
  /* ------------------------------ */
  //.5rem step
  .p-#{$i} {
    padding: $i * 0.5rem;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.5rem;
  }
  .pt-#{$i} {
    padding-top: $i * 0.5rem;
  }
  .pl-#{$i} {
    padding-left: $i * 0.5rem;
  }
  .pr-#{$i} {
    padding-right: $i * 0.5rem;
  }

  //.25rem step
  .p-sm-#{$i} {
    padding: $i * 0.25rem;
  }
  .pb-sm-#{$i} {
    padding-bottom: $i * 0.25rem;
  }
  .pt-sm-#{$i} {
    padding-top: $i * 0.25rem;
  }
  .pl-sm-#{$i} {
    padding-left: $i * 0.25rem;
  }
  .pr-sm-#{$i} {
    padding-right: $i * 0.25rem;
  }

  //0.125rem step
  .p-xs-#{$i} {
    padding: $i * 0.125rem;
  }
  .pb-xs-#{$i} {
    padding-bottom: $i * 0.125rem;
  }
  .pt-xs-#{$i} {
    padding-top: $i * 0.125rem;
  }
  .pl-xs-#{$i} {
    padding-left: $i * 0.125rem;
  }
  .pr-xs-#{$i} {
    padding-right: $i * 0.125rem;
  }
}
