mat-spinner {
  --spinner-color-privary: var(--color-primary);
  --spinner-color-secondary: var(--color-secondary);
  --spinner-color-attention: var(--color-attention);

  &.mat-progress-spinner circle,
  &.mat-spinner circle {
    stroke: var(--spinner-color-primary);
  }

  &.mat-progress-spinner.mat-accent circle,
  &.mat-spinner.mat-accent circle {
    stroke: var(--spinner-color-secondary);
  }

  &.mat-progress-spinner.mat-warn circle,
  &.mat-spinner.mat-warn circle {
    stroke: var(--spinner-color-attention);
  }
}
