ng-scrollbar {
  --scrollbar-Y-track-margin: 0px;
  --scrollbar-X-left-position: unset;
  --scrollbar-X-right-position: 0px;
  --scrollbar-X-top-position: 0px;
  --scrollbar-X-bottom-position: 0px;
  --scrollbar-Y-left-position: initial;
  --scrollbar-Y-right-position: 0px;
  --scrollbar-Y-top-position: 0px;
  --scrollbar-Y-bottom-position: 0px;
  --local__X-left: var(--scrollbar-X-left-position, 0);
  --local__X-right: var(--scrollbar-X-right-position, 0);
  --local__X-top: var(--scrollbar-X-top-position, 0);
  --local__X-bottom: var(--scrollbar-X-bottom-position, auto);
  --local__Y-left: var(--scrollbar-Y-left-position, auto);
  --local__Y-right: var(--scrollbar-Y-right-position, 0);
  --local__Y-top: var(--scrollbar-Y-top-position, 0);
  --local__Y-bottom: var(--scrollbar-Y-bottom-position, auto);

  &.autocomplete-list-scrollbar {
    --scrollbar-Y-track-margin: 0.325rem 0;
  }

  .ng-scrollbar-wrapper {
    scrollbar-y {
      margin: var(--scrollbar-Y-track-margin);
    }
  }
}

.custom-size__scrollbar {
  &-wrapper {
    .ng-scrollbar-wrapper {
      scrollbar-y {
        inset: var(--local__Y-top) var(--local__Y-right) var(--local__Y-bottom) var(--local__Y-left) !important;
      }

      scrollbar-x {
        inset: var(--local__X-top) var(--local__X-right) var(--local__X-bottom) var(--local__X-left) !important;
      }
    }

    &.mask-X {
      mask-image: linear-gradient(
        90deg,
        rgb(255 255 255 / 0%) calc(0% + 0.675rem),
        white calc(0% + 1rem),
        white calc(100% - 1rem),
        rgb(255 255 255 / 0%) calc(100% - 0.675rem)
      );
    }

    &.mask-Y {
      mask-image: linear-gradient(
        180deg,
        rgb(255 255 255 / 0%) calc(0% + 0.675rem),
        white calc(0% + 1rem),
        white calc(100% - 1rem),
        rgb(255 255 255 / 0%) calc(100% - 0.675rem)
      );
    }
  }

  &-track {
    filter: drop-shadow(1px 1px 4px var(--color-gray-800));
  }

  &-thumb {
    cursor: pointer;
    position: relative;

    &::after {
      position: absolute;
      content: '| | |';
      font-family: system-ui;
      font-size: 50%;
      line-height: 50%;
      letter-spacing: 3px;
      color: var(--color-gray-500);
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
