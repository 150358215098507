*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  object-fit: contain;
  pointer-events: none; /* элемент не может стать объектом мыши */ /* запрет на перенос текста */
  user-select: none;
}

img, video {
  max-width: none;
}

.max-width {
  max-width: var(--max-content-width);
  width: 100%;
}

.accent {
  color: var(--color-primary);
}

body {
  // display: flex;
  flex: 0 0 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  background-color: var(--color-theme-800);
  background-size: 100%;
  font-size: 1rem;
  text-align: left;
  color: var(--color-typography-light);
  text-size-adjust: 100%;
}

