.faq-block__wrapper{
  ul,
  ol {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    letter-spacing: 0.2px;

    //color: #fff;
    //opacity: 0.7;

    li {
      margin: 0.75rem 0 0.75rem 2rem;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

