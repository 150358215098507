.flex-inline {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex,
.flex-inline {
  &.fx-column {
    flex-direction: column;
  }

  &.fx-wrap {
    flex-wrap: wrap;
  }

  &.fx-all-center {
    align-items: center;
    justify-content: center;
  }

  &.fx-justify {
    &-center {
      justify-content: center;
    }

    &-between {
      justify-content: space-between;
    }

    &-around {
      justify-content: space-around;
    }

    &-end {
      justify-content: flex-end;
    }
  }

  &.fx-align {
    &-center {
      align-items: center;
    }

    &-start {
      align-items: flex-start;
    }

    &-end {
      align-items: flex-end;
    }

    &-baseline {
      align-items: baseline;
    }
  }

  @for $i from 1 through 10 {
    &.gap-lg-#{$i} {
      gap: $i * 1rem;
    }
    &.gap-#{$i} {
      gap: $i * 0.25rem;
    }
    &.gap-xs-#{$i} {
      gap: $i * 0.125rem;
    }
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

@for $i from 1 through 10 {
  .z-#{$i} {
    z-index: $i;
  }
  .z-lg-#{$i} {
    z-index: $i * 10;
  }
}
