// @import '~@angular/material/theming';

/* --------------- Breakpoints -------------- */
// внесены мною

$z-index-panel: 100;
$grid-breakpoints: (
  xs: 0,
  nm: 320px,
  im: 360px,
  mkm: 400px,
  mm: 480px,
  sm: 550px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  hg: 1400px,
  xh: 1600px,
);

/* ------------------------------- */
//грид от прайма
$separator: '\\:' !default;
$sm: 576px !default;

//начало планшетов
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$gutter: 0.5rem !default;
$fieldMargin: 1rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;
$spacer: 1rem !default;
$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
);

/* ----------------------------- */
$z-index-panel-second-level: $z-index-panel - 1;
$z-index-game-menu: $z-index-panel-second-level - 1;
$z-index-aside-panel: $z-index-game-menu - 1;
$z-index-widgets-menu: $z-index-panel - 2;
$z-main: $z-index-widgets-menu - 1;
$z-index-modal: 1000;
$z-index-tippy: 1050 !important;

/// new ui-kit
$attention: (
  success: #74ff0c,
  info: #0cc4ff,
  warning: #ffdb0c,
  error: #d80027,
);
$notification: (
  success: #4fb007,
  info: #008fb5,
  warning: #ed9100,
  error: #db193c,
);

/** buttons */
$primary: (
  '': #f6e444,
  'active': #fff7b5,
  'outline': #484419,
);
$button: (
  '': #f6e444,
  'hover': #fff7b5,
  'disable': #484419,
);
$action: (
  '': #757880,
  'hover': #e1e1e2,
  'active': #f6e444,
);
$typography: (
  'white': #ffffff,
  'light-gray': #e1e1e2,
  'gray-100': #a8adaf,
  'gray-200': #757880,
  'gray-300': #494b52,
  'gray-600': #2f3136,
  'gray-800': #202225,
  // старые
  'light': #ffffff,
  'medium': #e1e1e2,
  'dark': #202225,
  //
  'transparent': rgba(255, 255, 255, 0),
  'muted-30': rgba(255, 255, 255, 0.3),
  'muted-50': rgba(255, 255, 255, 0.5),
  'muted-70': rgba(255, 255, 255, 0.7),
);
$ui: (
  'white': #fff,
  'black': #000,
  'link': #2facff,
);

////

// было
// $secondary: (
//   '': #208698,
//   'active': #00BAD9,
//   'outline': #174851,
// );
// стало
$secondary: (
  '': #00bad9,
  'active': #208698,
  'outline': #174851,
);
$account: (
  'type-demo': #ee2c39,
  'type-bronze': #bd6428,
  'type-silver': #e2ffff,
  'type-gold': #ffcb5b,
  'type-platinum': #0d73cc,
  'type-diamond': #aa3af1,
);
$theme: (
  'blue': #266d7f,
  'gray': #757880,
  'old-blue': #205562,
);
$provider: (
  steam: #f6e444,
  google: #d3493b,
  vk: #507699,
  twitter: #2aa9ec,
  fb: #3a5a92,
  yandex: #d7143a,
  twitch: #6441a5,
  telegram: #57a8ea,
);
$game-modes: (
  pvp: #415ec4,
  pve: #317284,
  solo-pve: #5d8427,
);
$games: (
  cases: (
    'stat-trak': #b14131,
    'success-color': #89ca57,
    'free-color': #9dff7a66,
    'event-color': #3d87fb,
    'event-color-active': #619eff,
    'lost-color': #f95428,
    'win-color': #9dff7a,
  ),
);
$colors: (
  theme: $theme,
  attention: $attention,
  account: $account,
  notification: $notification,
  primary: $primary,
  secondary: $secondary,
  provider: $provider,
  typography: $typography,
  game-modes: $game-modes,
  action: $action,
  button: $button,
  ui: $ui,
);

:root {
  @each $name, $color in $colors {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        @if $subname == '' {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$name}: #{$color};
    }
  }

  @each $game, $color in $games {
    @if type-of($color) == 'map' {
      @each $subgame, $subcolor in $color {
        @if $subgame == '' {
          --color-#{$game}: #{$subcolor};
        } @else {
          --color-#{$game}-#{'' + $subgame}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$game}: #{$color};
    }
  }

  --panel-size: 19rem;
  --left-sidebar-size: 6.75rem;
  --right-sidebar-size: 6.75rem;
  --layout-width: 0rem;
  --main-background: theme('colors.gray.750');
  --main-color-background: theme('colors.gray.750');
  --main-image-background: url('/assets/img/background/bg-standart.jpg') center top / 100% no-repeat rgb(12 13 17);
  --shine-background: url('/assets/img/background/shine.png') center center / 100% no-repeat;
  --bonus-background: url('/assets/img/background/bonus-bg.svg') center center / 100% no-repeat;
}

.gray {
  $theme-100-color: #e1e1e2;
  $theme-200-color: #757880;
  $theme-300-color: #52565e;
  $theme-400-color: #42464d;
  $theme-500-color: #35373c;
  $theme-600-color: #2f3136;
  $theme-700-color: #27292d;
  $theme-800-color: #202225;
  $theme-900-color: #040606;
  $theme: (
    theme-100: (
      '': $theme-100-color,
      light: lighten($theme-100-color, 20%),
      dark: darken($theme-100-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-200: (
      '': $theme-200-color,
      light: lighten($theme-200-color, 20%),
      dark: darken($theme-200-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-300: (
      '': $theme-300-color,
      light: lighten($theme-300-color, 20%),
      dark: darken($theme-300-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-400: (
      '': $theme-400-color,
      light: lighten($theme-400-color, 20%),
      dark: darken($theme-400-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-500: (
      '': $theme-500-color,
      light: lighten($theme-500-color, 20%),
      dark: darken($theme-500-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-600: (
      '': $theme-600-color,
      light: lighten($theme-600-color, 20%),
      dark: darken($theme-600-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-700: (
      '': $theme-700-color,
      light: lighten($theme-700-color, 20%),
      dark: darken($theme-700-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-800: (
      '': $theme-800-color,
      light: lighten($theme-800-color, 20%),
      dark: darken($theme-800-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-900: (
      '': $theme-900-color,
      light: lighten($theme-900-color, 20%),
      dark: darken($theme-900-color, 20%),
      translucent: rgba($theme-900-color, 0.9),
    ),
  );

  @each $name, $color in $theme {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        @if $subname == '' {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$name}: #{$color};
    }
  }
}

.blue {
  $theme-100-color: #00bad9;
  $theme-200-color: #266d7f;
  $theme-300-color: #19343f;
  $theme-400-color: #213137;
  $theme-500-color: #182328;
  $theme-600-color: #121a1e;
  $theme-700-color: #0c1215;
  $theme-800-color: #0c0d11;
  $theme-900-color: #02020c;
  $theme: (
    theme-100: (
      '': $theme-100-color,
      light: lighten($theme-100-color, 20%),
      dark: darken($theme-100-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-200: (
      '': $theme-200-color,
      light: lighten($theme-200-color, 20%),
      dark: darken($theme-200-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-300: (
      '': $theme-300-color,
      light: lighten($theme-300-color, 20%),
      dark: darken($theme-300-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-400: (
      '': $theme-400-color,
      light: lighten($theme-400-color, 20%),
      dark: darken($theme-400-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-500: (
      '': $theme-500-color,
      light: lighten($theme-500-color, 20%),
      dark: darken($theme-500-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-600: (
      '': $theme-600-color,
      light: lighten($theme-600-color, 20%),
      dark: darken($theme-600-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-700: (
      '': $theme-700-color,
      light: lighten($theme-700-color, 20%),
      dark: darken($theme-700-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-800: (
      '': $theme-800-color,
      light: lighten($theme-800-color, 20%),
      dark: darken($theme-800-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-900: (
      '': $theme-900-color,
      light: lighten($theme-900-color, 20%),
      dark: darken($theme-900-color, 20%),
      translucent: rgba($theme-900-color, 0.9),
    ),
  );

  @each $name, $color in $theme {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        @if $subname == '' {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$name}: #{$color};
    }
  }
}

.old-blue {
  $theme-100-color: #717779;
  $theme-200-color: #266d7f;
  $theme-300-color: #205562;
  $theme-400-color: #384955;
  $theme-500-color: #2d3c46;
  $theme-600-color: #243038;
  $theme-700-color: #1d272d;
  $theme-800-color: #131c20;
  $theme-900-color: #040606;
  $theme: (
    theme-100: (
      '': $theme-100-color,
      light: lighten($theme-100-color, 20%),
      dark: darken($theme-100-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-200: (
      '': $theme-200-color,
      light: lighten($theme-200-color, 20%),
      dark: darken($theme-200-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-300: (
      '': $theme-300-color,
      light: lighten($theme-300-color, 20%),
      dark: darken($theme-300-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-400: (
      '': $theme-400-color,
      light: lighten($theme-400-color, 20%),
      dark: darken($theme-400-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-500: (
      '': $theme-500-color,
      light: lighten($theme-500-color, 20%),
      dark: darken($theme-500-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-600: (
      '': $theme-600-color,
      light: lighten($theme-600-color, 20%),
      dark: darken($theme-600-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-700: (
      '': $theme-700-color,
      light: lighten($theme-700-color, 20%),
      dark: darken($theme-700-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-800: (
      '': $theme-800-color,
      light: lighten($theme-800-color, 20%),
      dark: darken($theme-800-color, 20%),
      translucent: rgba($theme-800-color, 0.9),
    ),
    theme-900: (
      '': $theme-900-color,
      light: lighten($theme-900-color, 20%),
      dark: darken($theme-900-color, 20%),
      translucent: rgba($theme-900-color, 0.9),
    ),
  );

  @each $name, $color in $theme {
    @if type-of($color) == 'map' {
      @each $subname, $subcolor in $color {
        @if $subname == '' {
          --color-#{$name}: #{$subcolor};
        } @else {
          --color-#{$name}-#{$subname}: #{$subcolor};
        }
      }
    } @else if type-of($color) == 'color' {
      --color-#{$name}: #{$color};
    }
  }
}
