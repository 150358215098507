mat-form-field {
  --local__background-color: var(--background-color, transparent);
  --local__details-color: var(--details-color, transparent);

  &.ui-form,
  &.ui-input {
    --accent-color: var(--color-blue-500);
    --background-color: var(--color-gray-700);
    --details-color: var(--color-gray-300);
    --details-hover-color: var(--color-gray-100);
    --border-radius: 0.75rem;
    --border-color: var(--color-gray-600);
    --icon-size: 1rem;
  }

  &.market-basket-select {
    //FIXME Пора уже передавать не класс, а переменные
    --background-color: theme('colors.gray.700');
    --details-color: theme('colors.gray.400');
  }

  &.trade-select {
    --border-radius: 2.5rem;

    border: none;
  }

  &.promo {
    --background-color: var(--color-gray-800);
    --accent-color: var(--color-green-500);
  }

  &.refill {
    --background-color: var(--color-gray-800);
    --accent-color: var(--color-blue-500);
    --border-color: var(--color-gray-800);
  }

  &.filter-selector {
    .mat-mdc-text-field-wrapper {
      padding-left: 1rem;

      .mat-mdc-form-field-icon-suffix {
        transform: rotate3d(0, 0, -1, 90deg);

        @media screen and (width <= 374px) {
          display: none;
        }
      }

      @media screen and (width <= 374px) {
        .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select {
          padding-right: 1rem;
        }
      }
    }

    .mat-mdc-form-field-infix {
      width: 9rem;

      @media screen and (width <= 545px) {
        width: 5.5rem;
      }
    }

    &.mat-focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
      transform: rotate3d(0, 0, 1, 90deg);
    }
  }

  &.filter-search {
    --background-color: var(--color-gray-700);
    --accent-color: var(--color-gray-700);
    --border-color: var(--color-gray-700);
    --border-radius: 40px;

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .mat-mdc-form-field-text-prefix,
    .mat-mdc-form-field-text-suffix {
      display: flex;
    }

    mat-icon[svgIcon='search--design'] {
      height: 1rem;
    }

    mat-icon[svgIcon='close'] {
      height: 1.5rem;
    }
  }

  &.p2p-setting-form {
    --background-color: var(--color-gray-800);
    --accent-color: var(--color-blue-500);
    --border-color: var(--color-gray-800);

    .mat-mdc-text-field-wrapper {
      padding: 0 0.75rem;
    }

    &.ng-valid {
      border: 1px solid var(--color-green-500);
    }
  }

  &.p2p-autoselect {
    &.input-active .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-input-element {
      color: var(--color-gray-100);
      font-size: 1.125rem;
      font-weight: 500;
    }

    .mat-mdc-form-field-text-prefix {
      display: flex;
    }
  }

  &.compact {
    width: auto;
  }

  width: 100%;
  height: 2.75rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius) !important;
  background-color: var(--local__background-color);
  transition: border 150ms ease-in-out;

  .mat-mdc-text-field-wrapper {
    height: 2.5rem;
    padding: 0;
    padding-left: 0.75rem;
    overflow: visible;

    .mat-mdc-form-field {
      &-flex {
        align-items: center;
        height: 100%;

        .mat-mdc-form-field-infix {
          display: flex;
          gap: 0.5rem;
          justify-content: flex-start;
          align-items: center;
          height: 100%;

          .mat-mdc-select {
            --mat-select-trigger-text-size: 0.875rem;

            display: flex;
            align-items: center;
            height: 100%;

            &-trigger {
              height: 100%;

              .mat-mdc-select-value {
                overflow: hidden;

                &-text {
                  mat-select-trigger {
                    display: flex;
                    gap: 0.25rem;
                    align-items: center;
                    color: var(--details-color);
                  }
                }
              }

              .mat-mdc-select-arrow-wrapper {
                display: none;
              }
            }
          }

          .prefix-icon {
            display: block;
            height: auto !important;
            min-width: var(--icon-size) !important;
            max-width: var(--icon-size) !important;
            aspect-ratio: 1/1;
            pointer-events: none;

            &.yellow {
              color: var(--color-yellow-500);
            }

            &.green {
              color: var(--color-green-500);
            }

            &.blue {
              color: var(--color-blue-500);
            }

            &.image {
              border-radius: 50%;
              object-fit: fill;
            }
          }

          .mat-mdc-input-element {
            --mdc-outlined-text-field-caret-color: var(--details-color);

            background: none;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            color: var(--details-color);
            transition: color 150ms ease-in-out;

            &::placeholder {
              color: var(--color-gray-500);
            }

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              appearance: none;
              margin: 0;
            }

            /* Firefox */
            &[type='number'] {
              appearance: textfield;
            }
          }

          .mdc-floating-label {
            color: var(--details-color);
            max-width: none;

            mat-label {
              overflow: hidden;
              white-space: nowrap;
              width: calc(100% - 0.01px);
            }

            &--float-above {
              transform: translateY(-2.5rem) scale(0.75) !important;
              color: var(--details-hover-color);
            }
          }
        }

        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border: none;
            color: var(--details-color);
            transition: color 150ms ease-in-out;
          }

          &__notch {
            .mdc-floating-label {
              color: var(--details-color);
              max-width: none;

              mat-label {
                overflow: hidden;
                white-space: nowrap;
                width: calc(100% - 0.01px);
              }

              &--float-above {
                transform: translateY(-2.5rem) scale(0.75) !important;
                color: var(--details-hover-color);
              }
            }
          }
        }
      }

      &-icon-prefix {
        display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        height: 100%;
        color: var(--color-green-600);
      }

      &-icon-suffix {
        color: var(--details-color);
        transform: rotate3d(0, 0, 1, -90deg);
        padding: 0;
        transition:
          color 150ms ease-in-out,
          transform 150ms ease-in-out;

        // pointer-events: unset;
        cursor: pointer;
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: flex;
    height: 0;

    .mat-mdc-form-field-hint-wrapper {
      padding: 0;

      .mat-mdc-form-field-hint {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        color: var(--details-color);
        font-size: 0.875rem;
        height: 1.5rem;

        &::before {
          display: none;
        }

        &-spacer {
          display: none;
        }

        .link {
          margin-left: 0.125rem;
          color: var(--accent-color);
          cursor: pointer;
          pointer-events: all;
          user-select: inherit;
        }
      }
    }
  }

  &.mat-focused {
    border: 1px solid var(--accent-color);

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-select &-value &-text mat-select-trigger {
          color: var(--details-hover-color);
        }
      }

      .mat-mdc-form-field-icon-suffix {
        color: var(--accent-color);
        transform: rotate3d(0, 0, -1, -90deg);
      }

      .mdc-floating-label {
        transform: translateY(-2.5rem) scale(0.75);
        color: var(--details-hover-color);
      }

      input {
        color: var(--details-hover-color);
      }
    }
  }

  &:hover {
    border: 1px solid var(--details-color);
  }

  &.ng-dirty {
    &.ng-invalid {
      border: 1px solid var(--color-red-500);
    }
  }
}
