app-campaign-statistics {
  .period-selector {
    border-radius: 2.5rem;

    .mat-mdc-tab-links {
      height: 2.75rem;
      border-radius: 2.5rem;
      padding: 0.2rem;
      background-color: var(--color-gray-700);
    }
  }

  .period-label {
    border-radius: 2.5rem;

    .mdc-tab-indicator {
      display: none;
    }

    &.mdc-tab {
      height: 100% !important;
    }

    &.mdc-tab--active {
      background-color: var(--color-blue-500);
      color: var(--color-gray-100);
    }

    .mdc-tab__text-label {
      --mat-tab-header-active-focus-label-text-color: theme('colors.gray.100');
      --mat-tab-header-active-label-text-color: theme('colors.gray.100');

      color: var(--color-gray-400, #728188);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
