@import './mixins/fluid-font-size';

@font-face {
  font-family: RedFont;
  src: url('../fonts/RedFont/icomoon-red.eot');
  src: url('../fonts/RedFont/icomoon-red.eot#iefix') format('embedded-opentype'),
  url('../fonts/RedFont/icomoon-red.ttf') format('truetype'),
  url('../fonts/RedFont/icomoon-red.woff') format('woff'),
  url('../fonts/RedFont/icomoon-red.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;

  // unicode-range: U+25Ce, U+2b06, U+1f0cf, U+1f465, U+1f512, U+1f513, U+1f5fc;
  unicode-range: U+25Ce;
}

@font-face {
  font-family: CustomFont;
  src: url('../fonts/CustomFont/icomoon-custom.eot');
  src: url('../fonts/CustomFont/icomoon-custom.eot#iefix') format('embedded-opentype'),
  url('../fonts/CustomFont/icomoon-custom.ttf') format('truetype'),
  url('../fonts/CustomFont/icomoon-custom.woff') format('woff'),
  url('../fonts/CustomFont/icomoon-custom.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;

  // unicode-range: U+25Ce, U+2b06, U+1f0cf, U+1f465, U+1f512, U+1f513, U+1f5fc;
  unicode-range: U+25Ce;
}

/* jolly-lodger-regular - latin */
@font-face {
  font-family: JollyLodger;
  src: url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */
  url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../fonts/JollyLodger/jolly-lodger-v20-latin-regular.svg') format('svg'); /* Legacy iOS */

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RUSMade in Chinav2';
  src: url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.eot?#iefix') format('embedded-opentype'),
    /* IE < 9 */
  url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.woff') format('woff'),
    /* Firefox >= 3.6, any other modern browser */
  url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../fonts/RUSMadeinChinav2/rusmadeinchinav2-regular.svg#rusmadeinchinav2-regular') format('svg'); /* Chrome < 4, Legacy iOS */
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasCyrillic/Bebas Neue Cyrillic.ttf') format('truetype');
}

html {
  @include fluidFontSize(12px, 18px, 480px, 1920px, 16px);
}
